import { Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { ContactService } from 'src/app/services/contact.service';
import { MOCK } from './mock';
import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: 'app-contact-candidateRegistrationEvent',
  templateUrl: './contact.candidateRegistrationEventComponent.html',
  styleUrls: ['./contact.candidateRegistrationEventComponent.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CandidateRegistrationEventComponent implements OnInit {

  @Input('form_data') form_data: any;
  @Output() show = new EventEmitter<boolean>();
  @Input('opacity') opacity: '0.3';
  mock = MOCK;
  isRequried = false;
  isSubmitted = false;
  isSpinner = false;
  isError = false;
  isSuccess = false;
  emailRegex = /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!aol.com)(?!hotmail.co.uk)(?!hotmail.fr)(?!msn.com)(?!yahoo.fr)(?!wanadoo.fr)(?!orange.fr)(?!comcast.net)(?!yahoo.co.uk)(?!yahoo.com.br)(?!yahoo.co.in)(?!live.com)(?!rediffmail.com)(?!free.fr)(?!gmx.de)(?!web.de)(?!yandex.ru)(?!ymail.com)(?!libero.it)(?!outlook.com)(?!uol.com.br)(?!bol.com.br)(?!mail.ru)(?!cox.net)(?!hotmail.it)(?!sbcglobal.net)(?!sfr.fr)(?!live.fr)(?!verizon.net)(?!live.co.uk)(?!googlemail.com)(?!yahoo.es)(?!ig.com.br)(?!live.nl)(?!bigpond.com)(?!terra.com.br)(?!yahoo.it)(?!neuf.fr)(?!yahoo.de)(?!alice.it)(?!rocketmail.com)(?!att.net)(?!laposte.net)(?!facebook.com)(?!bellsouth.net)(?!yahoo.in)(?!hotmail.es)(?!charter.net)(?!yahoo.ca)(?!yahoo.com.au)(?!rambler.ru)(?!hotmail.de)(?!tiscali.it)(?!shaw.ca)(?!yahoo.co.jp)(?!sky.com)(?!earthlink.net)(?!optonline.net)(?!freenet.de)(?!t-online.de)(?!aliceadsl.fr)(?!virgilio.it)(?!home.nl)(?!qq.com)(?!telenet.be)(?!me.com)(?!yahoo.com.ar)(?!tiscali.co.uk)(?!yahoo.com.mx)(?!voila.fr)(?!gmx.net)(?!mail.com)(?!planet.nl)(?!tin.it)(?!live.it)(?!ntlworld.com)(?!arcor.de)(?!yahoo.co.id)(?!frontiernet.net)(?!hetnet.nl)(?!live.com.au)(?!yahoo.com.sg)(?!zonnet.nl)(?!club-internet.fr)(?!juno.com)(?!optusnet.com.au)(?!blueyonder.co.uk)(?!bluewin.ch)(?!skynet.be)(?!sympatico.ca)(?!windstream.net)(?!mac.com)(?!centurytel.net)(?!chello.nl)(?!live.ca)(?!aim.com)(?!bigpond.net.au)([\w-]+\.)+[\w-]{2,4})?$/;
  submitImage = '/assets/images/Success_background.png';
  submitIcon = '/assets/icons/Success_icon.svg';
  spinnerIcon = '/assets/images/Spinner.svg';
  errorIcon = '/assets/images/error_image.png';
  file = null;
  constructor(
    private contactService: ContactService,
    public fb: FormBuilder,
  ) { }

  contactForm = this.fb.group({
    event_name: "",
    candidate_name: new FormControl('', [Validators.required,  Validators.maxLength(70)]),
    major: new FormControl('', [Validators.required,  Validators.maxLength(70)]),
    university: new FormControl('', [Validators.required,  Validators.maxLength(70)]),
    position: new FormControl('', [Validators.required,  Validators.maxLength(70)]),
    experience: new FormControl('', [Validators.required,  Validators.maxLength(70)]),
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(160)]),
    contact_number: new FormControl('', [
      Validators.required, Validators.maxLength(10), Validators.minLength(10),
      Validators.pattern('[0]{1}[0-9]{9}')
    ]),
    file_attachment: new FormControl('', [Validators.required]),
  })
  ngOnInit() {
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.isSpinner = true;
      this.show.emit(this.isSpinner);

      this.contactForm.controls.event_name.setValue(this.form_data.email_Subject);
      this.contactForm.controls.contact_number.setValue('+966' + this.contactForm.value.contact_number.substring(1));
      this.contactService.candidateRegistrationContact(this.contactForm.value)
        .subscribe(
          data => {
            setTimeout(() => {
              this.isSpinner = false;
              this.isSubmitted = true;
              this.isSuccess = true;
            }, 2000);
          },
          err => {
            setTimeout(() => {
              this.isSpinner = false;
              this.isSubmitted = true;
              this.isError = true;
            }, 2000);
            console.log('err: ', err)},
            )
    } else {
      this.validateAllFormFields(this.contactForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    this.isRequried = true;
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);     
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
        
      }
    });
  }

  //file type validation
  onFileChangeFromFile(event:any)
  {
      if (event.target.files && event.target.files[0]) {
        this.file = event.target.files[0];
        
        if(this.file.type == "application/msword" || this.file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || this.file.type == "application/pdf") {
          this.contactForm.controls.file_attachment.setValue(this.file);
        }
        else {
          //file validation
          this.contactForm.controls["file_attachment"].setValidators([Validators.required]);
          this.contactForm.get('file_attachment').setErrors({invalid: true});
          this.contactForm.controls["file_attachment"].markAsTouched({ onlySelf: true });
          this.contactForm.controls["file_attachment"].markAsDirty();
        }
      }
  }
}
