import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[backendTranslate]'
})
export class BackendTranslateDirective implements OnInit {

  el;

  constructor(
    el: ElementRef,
    private translate: TranslateService,
  ) {
    this.el = el;
  }

  @Input('translateObject') backendObject: string;
  @Input('translateName') backendName: string;
  @Input('translateSlice') backendSlice: Array<number> = [0, 0];
  @Input('translateSliceWords') translateSliceWords: boolean = false;

  ngOnInit() {
    setTimeout(() => {
      this.el.nativeElement.innerHTML = (this.translate.getDefaultLang() == 'ar') ? this.slice(this.backendObject['ar_' + this.backendName]) : this.slice(this.backendObject['en_' + this.backendName]);
    })
  }

  slice(string) {
    if (this.translateSliceWords == false) {
      if (this.backendSlice[1] > 0) {
        let end = (string.length > this.backendSlice[1]) ? '...' : '';
        return string.slice(this.backendSlice[0], this.backendSlice[1]) + end;
      } else {
        return string;
      }
    } else {
      // console.log('limit')
      return this.limitWords(string);
    }
  }

  limitWords(string) {
    let text = string.replace(/\s+/g, ' ');
    let text2 = text.split(' ');

    let numberOfWords = text2.length;
    let i = 0;
    let finalText = "";

    if (numberOfWords > this.backendSlice[1]) {
      for (i = 0; i < this.backendSlice[1]; i++)
        finalText = finalText + " " + text2[i];

      return finalText + "...";
    }
    else return string;
  }

}
