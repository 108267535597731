import { Component } from '@angular/core';
import { MOCK } from './mock';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {

  mock = MOCK;

  constructor(
    private translate: TranslateService,
    private productService: ProductService
  ) {
    this.productService.GetProducts().subscribe((data) => {
      let arr = [];
      for (let value of data) {
        const title = value.en_title.toLowerCase().split(' ').join('_');
        arr.push({
          title: (this.translate.getDefaultLang() == 'ar') ? value.ar_title : value.en_title,
          image: value.logo,
          description: (this.translate.getDefaultLang() == 'ar') ? value.ar_description : value.en_description,
          route: `/products/${title}`
        });
      }
      // data.forEach((value) => {

      //   arr.push({
      //     title: (this.translate.getDefaultLang() == 'ar') ? value.ar_title : value.en_title,
      //     image: value.logo,
      //     description: (this.translate.getDefaultLang() == 'ar') ? value.ar_description : value.en_description,
      //     route: `/products/${value.en_title}`
      //   });

      // });

      this.mock.products = arr;
    })
  }


}
