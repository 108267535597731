export const MOCK = {
  slides: [
    {
      heading: "header.nav.join_us",
      image: "/assets/images/mock/static/join_us.webp",
    },
  ],
  breadcrumbs: [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/join-us",
      title: "header.nav.join_us",
    },
  ],
  second_section: {
    title: "join_us.second_section.title",
    description: "join_us.second_section.description",
  },
  values_title: "about.values",
  values: [
    {
      image: "/assets/images/mock/about/Pioneering.svg",
      title: "about.values-2-title",
      desc: "about.values-2-desc",
    },
    {
      image: "/assets/images/mock/about/Agile.svg",
      title: "about.values-1-title",
      desc: "about.values-1-desc",
    },
    {
      image: "/assets/images/mock/about/Persevering.svg",
      title: "about.values-3-title",
      desc: "about.values-3-desc",
    },
    {
      image: "/assets/images/mock/about/Dependable.svg",
      title: "about.values-4-title",
      desc: "about.values-4-desc",
    },
  ],
  title: "join_us.title",
  description: "join_us.description",
  section3_title: "join_us.section3_title",
  content: [
    {
      image: "/assets/images/mock/static/join_us_who_we_are.jpg",
      title: "join_us.section1.title",
      description: "join_us.section1.description",
    },
    {
      image: "/assets/images/mock/join/join_us_why.jpg",
      title: "join_us.section2.title",
      description: "join_us.section2.description",
    },
    {
      image: "/assets/images/mock/join/join_us_culture.jpg",
      title: "join_us.section3.title",
      description: "join_us.section3.description",
    },
  ],

  feedback: {
    feedback: "Hear It From",
  },
  explore: {
    title: "join_us.explore.title",
    items: [
      {
        image: "/assets/images/mock/static/join_us_career.jpg",
        title: "join_us.career.title",
        description: "join_us.career.description",
        button: "join_us.career.button",
        link: "https://www.linkedin.com/company/sitesaudi/jobs/",
      },
      {
        image: "/assets/images/mock/static/join_us_coop.jpg",
        title: "join_us.coop.title",
        description: "join_us.coop.description",
        button: "join_us.coop.button",
        link: "https://www.linkedin.com/company/sitesaudi/jobs/",
      },
      {
        image: "/assets/images/mock/static/join_us_institute.jpg",
        title: "join_us.institute.title",
        description: "join_us.institute.description",
        button: "join_us.institute.button",
        route: "/join-us/programs",
      },
    ],
  },
  estimate: {
    title: "join_us.estimate.title",
    description: "join_us.estimate.description",
    button: "join_us.estimate.button",
    link: "join_us.estimate.link",
  },
};
