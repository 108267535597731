import { SubserviceComponent } from '../pages/services-and-solutions/subservice/subservice.component';

export const SubserviceRoute = {
  path: ':subslug',
  component: SubserviceComponent,
  data: {
    meta: {
      
    }
  }
}