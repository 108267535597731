import {
  Component,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  Input,
  PLATFORM_ID,
  Inject,
} from "@angular/core";
import { isPlatformServer } from "@angular/common";
import anime from "animejs/lib/anime.es.js";
import Swiper from "swiper";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

@Component({
  selector: "app-home-slider",
  templateUrl: "./home-slider.component.html",
  styleUrls: ["./home-slider.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeSliderComponent {
  @Input("slides") slides: Array<Object>;
  @Input("smallerHeading") smallerHeading: boolean = false;
  @ViewChild("homeSlider", { static: false }) homeSlider: ElementRef;

  public slider;

  public swiperOptions = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    centeredSlides: true,
    initialSlide: 1,
    allowTouchMove: false,
    touchRatio: 0,
    freeMode: false,
    preventClicks: true,
    centeredSlidesBounds: true,
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private translateService: TranslateService
  ) {}

  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId)) {
      this.slider = new Swiper(
        this.homeSlider.nativeElement,
        this.swiperOptions
      );
      this.slider.pagination.update();
      this.animateCurve();

      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.animateCurve();
      });

      setTimeout(() => {
        this.animateIn();
      }, 500);

      this.slider.on("slideChange", () => {
        this.animateIn();

        if (this.slider.activeIndex == this.slider.slides.length - 1) {
          setTimeout(() => {
            this.slider.slideTo(0, 600);
            this.slider.autoplay.start();
          }, 6000);
        }
      });
    }
  }

  animateCurve() {
    setTimeout(() => {
      const curved = document.querySelectorAll(".home-slider__slide--curved");

      anime({
        targets: curved,
        bottom: "-164px",
        rotate: this.isRTL() ? 5 : -5,
        duration: 1500,
        easing: "easeInOutSine",
      });
    });
  }

  animateIn() {
    const slide = this.slider.slides[this.slider.realIndex];
    const heading = slide.querySelector(".home-slider__slide--heading");
    const background = slide.querySelector(".home-slider__slide--background");
    const buttons = slide.querySelector(".home-slider__buttons");
    // const grid = slide.querySelector(".home-slider__slide--grid");

    heading.style.opacity = 0;
    // heading.style.marginTop = "300px";

    // grid.style.opacity = 0;

    buttons.style.opacity = 0;
    buttons.style.pointerEvents = "none";
    buttons.style.marginTop = "60px";

    anime({
      targets: [heading],
      // marginTop: "98px",
      opacity: 1,
      easing: "easeInOutQuad",
    });

    // background.style.opacity = 0;

    if (this.isRTL()) {
      if (window.innerWidth < 768) {
        background.style.marginLeft = "0";
      } else {
        background.style.marginLeft = "-100px";
      }

      anime({
        targets: [background],
        marginLeft: "0px",
        opacity: 1,
        easing: "linear",
      });
    } else {
      if (window.innerWidth < 768) {
        background.style.marginRight = "0";
      } else {
        background.style.marginRight = "-100px";
      }

      anime({
        targets: [background],
        marginRight: "0px",
        opacity: 1,
        easing: "linear",
      });
    }

    setTimeout(() => {
      anime({
        targets: [buttons],
        opacity: 1,
        marginTop: "0px",
        easing: "easeInOutQuad",
      });

      buttons.style.pointerEvents = "all";
    }, 5);
  }

  isRTL() {
    const app = document.querySelector("#app");
    return app.getAttribute("dir") == "rtl" ? true : false;
  }

  ngOnDestroy() {
    if (!isPlatformServer(this.platformId)) {
      this.slider.pagination.update();
      this.slider.destroy();
    }
  }
}
