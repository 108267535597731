import { Component, OnInit } from '@angular/core';
import { MOCK } from './mock';
import { TrainingsService } from 'src/app/services/trainings.service';

@Component({
  selector: 'app-institute',
  templateUrl: './institute.component.html',
  styleUrls: ['./institute.component.scss']
})
export class InstituteComponent implements OnInit {

  mock = MOCK;
  
  constructor(
    private trainingService: TrainingsService
  ) { }

  ngOnInit() {
    this.trainingService.GetAvailableTrainings().subscribe((data) => {
      this.mock.programs = data;
    })

    this.trainingService.GetPreviousTrainings().subscribe((data) => {
      this.mock.previous_programs = data;

      let sorted = {};

      Object.keys(this.mock.previous_programs).forEach((program) => {
        sorted[this.mock.previous_programs[program].date.split('-')[0]] = [];
      })

      Object.keys(this.mock.previous_programs).forEach((program) => {
        sorted[this.mock.previous_programs[program].date.split('-')[0]].push(this.mock.previous_programs[program])
        this.mock.picked_year = this.mock.previous_programs[program].date.split('-')[0]
      })
   

      this.mock.previous_programs = sorted;
    })
  }

}
