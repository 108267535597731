import {
  Component,
  Input,
  ViewEncapsulation,
  PLATFORM_ID,
  Inject,
  ChangeDetectionStrategy,
} from "@angular/core";
import { isPlatformServer } from "@angular/common";
import { Router } from "@angular/router";

@Component({
  selector: "app-home-services-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent {
  @Input("list") list: any;
  activeIndex = 0;

  itemPicked: any;
  lang = window.location.href.startsWith("/en") ? "en" : "ar";
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router
  ) {}
  ngOnInit() {
    this.itemPicked = this.list && this.list[0];
  }
  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId)) {
      if (window.innerWidth < 768) {
        this.activeIndex = null;
      }
    }
  }

  hovered(index) {
    if (window.innerWidth > 768) {
      this.activeIndex = index;
    }
  }

  selectItem(item, index) {
    this.activeIndex = index;
    this.itemPicked = item;
  }
  goToService(item, index) {
    const title = item.en_title.toLowerCase().replaceAll(" ", "_");
    if (this.activeIndex == index) {
      this.router.navigate(["services-and-solutions/" + title]);
    }
    this.activeIndex = index;
  }
}
