import { ServiceComponent } from '../pages/services-and-solutions/service/service.component';
import { MetaGuard } from '@ngx-meta/core';
import { SubserviceRoute } from './subservice';

export const ServiceRoute = {
  path: ':slug',
  canActivateChild: [MetaGuard],
  children: [
    {
      path: '',
      component: ServiceComponent,
    },
    SubserviceRoute
  ],
  data: {
    meta: {
      
    }
  }
}