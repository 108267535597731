import { Component, OnInit } from "@angular/core";

import { MOCK } from "./mock";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MetaService } from "@ngx-meta/core";
import { Article } from "src/app/models/article";
import { ArticleService } from "src/app/services/article.service";

@Component({
  selector: "app-article",
  templateUrl: "./article.component.html",
  styleUrls: ["./article.component.scss"],
})
export class ArticleComponent implements OnInit {
  mock = MOCK;

  slug: string;
  title: string;
  description: string;
  id;
  links: any = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private articleService: ArticleService,
    private meta: MetaService
  ) {}

  ngOnInit() {
    this.route.params.forEach((param) => {
      this.slug = param.slug;
      this.articleService.GetArticles().subscribe((articles) => {
        const tempData = articles.filter(
          (a) =>
            a.en_title
              .replace(/ /g, "_")
              .toLowerCase()
              .replace(/[^A-Za-z0-9_]/g, "") == this.slug
        );
        if (tempData.length < 1) {
          this.router.navigate([`/${this.translate.currentLang}/404`]);
          return;
        }
        this.id = tempData[0].id;
        this.articleService.GetArticle(this.id).subscribe((data) => {
          this.title =
            this.translate.getDefaultLang() == "ar"
              ? data.ar_title
              : data.en_title;
          this.description =
            this.translate.getDefaultLang() == "ar"
              ? data.ar_news
              : data.en_news;
          if (data.news_links.length > 0) {
            data.news_links.forEach((link) => {
              this.links.push({
                title:
                  this.translate.getDefaultLang() == "ar"
                    ? link.ar_title
                    : link.en_title,
                news_url:
                  this.translate.getDefaultLang() == "ar"
                    ? link.ar_news_url
                    : link.en_news_url,
              });
            });
          } else {
            this.links = undefined;
          }
          this.meta.setTitle(this.title);
          this.meta.setTag("description", this.description);
          this.meta.setTag("og:image", data.image);
          this.mock.description = this.description;
          this.mock.links = this.links;
          console.log("links: ", this.mock.links);

          this.mock.slides[0].image = data.image;
          this.mock.slides[0].subtitle = data.date;
          this.mock.slides[0].heading = this.title;
          this.mock.slides[0].arabicFlipped = true;

          if (this.mock.breadcrumbs.length == 3) {
            this.mock.breadcrumbs.pop();
          }

          this.mock.breadcrumbs.push({
            link: "/news/" + this.slug,
            title: this.title,
          });
        });
      });
    });
  }
}
