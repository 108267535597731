//import { MetaGuard } from '@ngx-meta/core';
import { PoliciesFormsComponent } from '../pages/policies-and-forms/policies-and-forms.component';

export const PoliciesFormsRoute = {
  path: 'suppliers-service-providers',
  //canActivateChild: [MetaGuard],
  children: [
    {
      path: '',
      component: PoliciesFormsComponent,
    },
  ],
  data: {
    meta: {
      title: 'Suppliers and Service Providers',
    }
  }
}