import {
  Component,
  ViewEncapsulation,
  Input,
  PLATFORM_ID,
  Inject,
} from "@angular/core";
import { isPlatformServer } from "@angular/common";
import anime from "animejs/lib/anime.es.js";
import { BusinessLineService } from "src/app/services/business-line.service";

@Component({
  selector: "app-home-services",
  templateUrl: "./home-services.component.html",
  styleUrls: ["./home-services.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeServicesComponent {
  @Input("content") content: any;
  services;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private businessLineService: BusinessLineService
  ) {}

  ngOnInit() {
    this.businessLineService.GetBusinessLines().subscribe((data) => {
      this.services = data;
    });
  }

  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId)) {
      setTimeout(() => {
        this.fadeElements();
      }, 500);
    }
  }

  fadeElements() {
    const elements = document.querySelectorAll(".home-services--fade");

    anime({
      targets: elements,
      opacity: 1,
      easing: "easeInOutQuad",
    });
  }
}
