export const MOCK = {
  slides: [
    {
      subtitle: "",
      image: "",
      headingIcon: "",
      slogan: "",
      firstButtonText: "",
      firstButtonLink: "",
      secondButtonText: "",
      secondButtonLink: "",
    },
  ],
  breadcrumbs: [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/products",
      title: "header-slider.products",
    },
  ],
  what_is: "What it is",
  description:
    "SITE Analyze is an online platform that detects vulnerabilities in web applications which may get exploited by attackers. In addition, this platform provides a vulnerability management system to keep track of the existing vulnerabilities.",
  second_description:
    "The objective of SITE Analyze is to automate vulnerability assessment tasks and processes, and improve the overall security of web applications by identifying the vulnerabilities that can negatively affect the security of web application.",
  how_it_works: "product.how_it_works",
  estimate: {
    title: "estimate.title",
    description: "estimate.desc",
    button: "estimate.button",
    link: "https://site.sa/contact-us",
  },
  features: [],
  benefits: [],
};
