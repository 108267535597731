export const MOCK = {
  slides: [
    {
      heading: "join_us.institute.title",
      image: '/assets/images/mock/static/institute.jpg',
    }
  ],
  breadcrumbs: [
    {
      link: "/",
      title: "Home"
    },
    {
      link: "/join-us",
      title: "header.nav.join_us"
    },
    {
      link: "/join-us/programs",
      title: "join_us.institute.title"
    }
  ],
  title: "Find a Career Worth Having",
  description: "Our services have been designed to ensure that our clients, whatever their fields, can make the transformative leap needed in the national digital and cybersecurity space, preparing the local industry to lead in the fourth industrial revolution.",
  about_title: "About",
  descriptions: [
    {
      title: "",
      content: "institute.about_overview"
    },
    {
      title: "institute.mission",
      content: "institute.mission_overview"
    },
    {
      title: "institute.strategic",
      content: "institute.strategic_overview"
    }
  ],
  programs: [
  ],
  previous_programs: {},
  picked_year: null
}