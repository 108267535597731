import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  ViewEncapsulation,
} from "@angular/core";
import { isPlatformServer } from "@angular/common";

@Component({
  selector: "app-error-not-found",
  templateUrl: "./error-not-found.component.html",
  styleUrls: ["./error-not-found.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorNotFoundComponent implements OnInit {
  public lang;
  constructor(@Inject(PLATFORM_ID) private platformId) {}

  ngOnInit() {
    window.location.pathname.startsWith("/en")
      ? (this.lang = "en")
      : (this.lang = "ar");
  }

  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId)) {
      const header = document.querySelector(".header");
      // header.classList.add('header__white')
    }
  }

  // ngOnDestroy() {
  //   if (!isPlatformServer(this.platformId)) {
  //     const footer = document.querySelector('.footer');
  //     footer.classList.remove('footer__fixed')
  //   }
  // }
}
