import {
  Component,
  ViewEncapsulation,
  Input,
  ViewChild,
  ElementRef,
  PLATFORM_ID,
  Inject,
  OnInit,
} from "@angular/core";
import anime from "animejs/lib/anime.es.js";
import { isPlatformServer } from "@angular/common";
import Swiper from "swiper";
import { TranslateService } from "@ngx-translate/core";
import { ProductService } from "src/app/services/product.service";
import { Product } from "src/app/models/product";
import { Router } from "@angular/router";

@Component({
  selector: "app-home-products",
  templateUrl: "./home-products.component.html",
  styleUrls: ["./home-products.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeProductsComponent implements OnInit {
  @Input("content") content: any;
  @ViewChild("productDesc", { static: false }) productDesc: ElementRef;
  @ViewChild("productSlider", { static: false }) productSlider: ElementRef;

  parallax;
  columnPicked;
  itemPicked;
  list;

  public slider;

  public swiperOptions = {
    slidesPerView: 1.5,
    slidesPerGroup: 1,
    centeredSlides: true,
    centerInsufficientSlides: true,
    centeredSlidesBounds: true,
    initialSlide: 1,
    loop: true,
    spaceBetween: 20,
    // width: 380,
    rtl: null,
    innerWidth: "100px",
    autoplay: {
      delay: 4000,
    },
    pagination: {
      el: ".products-swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  };
  activeIndex: any;

  constructor(
    private translate: TranslateService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId,
    private productService: ProductService
  ) {}

  ngOnInit() {
    this.productService.GetProducts().subscribe((data) => {
      let arr = [];
      for (let i = 0; i < 6; i++) {
        let value = data[i];
        const title = value.en_title.toLowerCase().split(" ").join("_");
        arr.push({
          title:
            this.translate.getDefaultLang() == "ar"
              ? value.ar_title
              : value.en_title,
          image: value.logo,
          description:
            this.translate.getDefaultLang() == "ar"
              ? value.ar_description
              : value.en_description,
          route: `/products/${title}`,
        });
      }
      this.list = arr;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!isPlatformServer(this.platformId) && this.isMobile()) {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper(
          this.productSlider.nativeElement,
          this.swiperOptions
        );
      }
    }, 500);
  }

  selectItem(column, index) {
    this.columnPicked = column;
    this.itemPicked = index;
    // anime({
    //   targets: window.document.scrollingElement || window.document.body || window.document.documentElement,
    //   scrollTop: this.productDesc.nativeElement.getBoundingClientRect().top + window.scrollY - 100,
    //   duration: 500,
    //   easing: 'easeInOutQuad'
    // });
  }

  isMobile() {
    if (!isPlatformServer(this.platformId)) {
      return window.innerWidth < 600 ? true : false;
    } else {
      return false;
    }
  }
  goToProduct(prod, index) {
    if (window.innerWidth > 960) {
      this.router.navigate([prod.route]);
    }

    if (window.innerWidth < 960 && this.activeIndex == index) {
      this.router.navigate([prod.route]);
    }

    this.activeIndex = index;
  }
}
