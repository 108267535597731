import { Component, ViewEncapsulation, Input} from '@angular/core';


@Component({
  selector: 'app-campaign-header',
  templateUrl: './campaign-header.component.html',
  styleUrls: ['./campaign-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CampaignHeaderComponent {
  @Input('header') header;
  @Input('reverseImage') reverseImage: boolean = false;


  constructor() { }

  ngOnInit() { }

}
