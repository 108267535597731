import { Component, ViewEncapsulation, Input } from '@angular/core';
declare var VanillaTilt;

@Component({
  selector: 'app-flexible-counters',
  templateUrl: './counters.component.html',
  styleUrls: ['./counters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CountersComponent {
  @Input('content') content: any;
  @Input('background') background: string = '#FFFFFF';
  @Input('image') image: string = '/assets/images/achievements.svg';
  @Input('index') index: string = '2';

  constructor(
  ) { }

  ngAfterViewInit() {

  }

}
