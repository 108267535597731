import { Component, OnInit } from '@angular/core';
import { MOCK } from './mock';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  mock = MOCK;
  constructor() { }

  ngOnInit() {
  }


}
