import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProgramsComponent implements OnInit {

  @Input('id') id: string;
  @Input('title') title: string;
  @Input('index') index: string;
  @Input('programs') programs: Array<object>;
  
  constructor() { }

  ngOnInit() {
  }

  goToTraining(url) {
    window.open(url);
  }

}
