import { Component, OnInit } from "@angular/core";
import { MOCK } from "./mock";
import { Award } from "../../../models/award";
import { AwardsAndCertificationsService } from "src/app/services/awards-and-certifications.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-awards-and-certifications",
  templateUrl: "./awards-and-certifications.component.html",
  styleUrls: ["./awards-and-certifications.component.scss"],
})
export class AwardsAndCertificationsComponent implements OnInit {
  mock = MOCK;

  isReports = window.location.pathname.includes("/reports") ? true : false;
  awards: Array<Award>;

  constructor(
    private translate: TranslateService,
    private awardsService: AwardsAndCertificationsService
  ) {}

  ngOnInit() {
    this.awardsService.GetAwardsAndCertifications().subscribe((data) => {
      this.awards = data;
      this.handleSlider(data);
    });
  }

  handleSlider(awards) {
    this.mock.slides = [];
    awards.forEach((award, index) => {
      if (award.awards_documents) {
        award.document =
          this.translate.getDefaultLang() == "ar"
            ? award.awards_documents.document
            : award.awards_documents.document;
      }

      if (award.awards_links) {
        award.link =
          this.translate.getDefaultLang() == "ar"
            ? award.awards_links.ar_awards_url
            : award.awards_links.ar_awards_url;
      }
      if (index < 3) {
        this.mock.slides.push({
          heading:
            this.translate.getDefaultLang() == "ar"
              ? award.ar_title
              : award.en_title,
          image: award.image,
          firstButtonLink: award.awards_documents ? award.document : award.link,
          firstButtonText: "about-awards.display_more",
          arabicFlipped: true,
        });
      }
    });
  }
}
