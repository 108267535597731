import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { BusinessLineService } from 'src/app/services/business-line.service';
import { ProductService } from 'src/app/services/product.service';
import { ArticleService } from 'src/app/services/article.service';
import { TrainingsService } from 'src/app/services/trainings.service';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss']
})
export class SitemapComponent implements OnInit {
  services = [];
  products = [];
  news = [];
  reports = [];
  availablePrograms = [];
  previousPrograms = [];


  constructor(
    private translateService: TranslateService,
    private businessLineService: BusinessLineService,
    private productService: ProductService,
    private articleService: ArticleService,
    private trainingService: TrainingsService

  ) { }

  ngOnInit() {

    //services  
    this.GetBusinessLines();

    //products
    this.GetProducts();

    //news
    this.GetArticles();

    //reports
    this.GetReports();

    //available programs
    this.GetAvailableTrainings();

    //previous programs
    this.GetPreviousTrainings();

  }
  GetBusinessLines() : void {
    this.businessLineService.GetBusinessLines().subscribe((data) => {
      data.forEach(element => {
        this.services.push({ title: (this.translateService.currentLang == 'ar' ? element.ar_title : element.en_title), url: element.en_title.split(' ').join('_').toLowerCase() })
      });
    });
  }

  GetProducts() : void {
    this.productService.GetProducts().subscribe((data) => {
      data.forEach(element => {
        this.products.push({ title: (this.translateService.currentLang == 'ar' ? element.ar_title : element.en_title), url: element.en_title.split(' ').join('_').toLowerCase() })
      });
    });
  }

  GetArticles() : void {
    this.articleService.GetArticles().subscribe((data) => {
      data.forEach(element => {
        this.news.push({ title: (this.translateService.currentLang == 'ar' ? element.ar_title : element.en_title), url: element.en_title.split(' ').join('_').toLowerCase().replace(/[^A-Za-z0-9_]/g, '') })
      });
    });
  }
  GetReports() : void {
    this.articleService.GetReports().subscribe((data) => {
      data.forEach(element => {
        this.reports.push({ title: (this.translateService.currentLang == 'ar' ? element.ar_title : element.en_title), document: this.translateService.currentLang == 'ar'? element.ar_document: element.en_document })
      });
    });
  }
  GetAvailableTrainings() : void {
    this.trainingService.GetAvailableTrainings().subscribe((data) => {
      data.forEach(element => {
        this.availablePrograms.push({ title: (this.translateService.currentLang == 'ar' ? element.ar_title : element.en_title), url: element.training_url })
      });
    });
  }
  GetPreviousTrainings(): void {
    this.trainingService.GetPreviousTrainings().subscribe((data) => {
      data.forEach(element => {
        this.previousPrograms.push({ title: (this.translateService.currentLang == 'ar' ? element.ar_title : element.en_title), url: element.training_url })
      });
    });
  }
}
