export const MOCK = {
  description: "home-slider.slides.innovative_and_trusted_description",
  heading: "home-slider.slides.innovative_and_trusted",
  sub_heading: "home-slider.slides.innovative_and_trusted_subHeading",
  image: "/assets/images/mock/home/HeaderHome.jpg",
  firstButtonLink: "/about-us",
  firstButtonText: "home-slider.slides.learn_more",
  slides: [
    {
      heading: "home-slider.slides.innovative_and_trusted",
      sub_heading: "home-slider.slides.innovative_and_trusted_subHeading",
      image: "/assets/images/mock/home/HeaderHome.jpg",
      firstButtonLink: "/about-us",
      firstButtonText: "home-slider.slides.learn_more",
    },
  ],
  // {
  //   heading: "home-slider.slides.innovative_and_professional",
  //   image: "/assets/images/mock/home/site-2.jpg",
  //   firstButtonLink:
  //     "/services-and-solutions/cybersecurity_services_and_solutions",
  //   firstButtonText: "home-slider.slides.explore_our_services",
  // },
  // {
  //   heading: "home-slider.slides.end_to_end",
  //   image: "/assets/images/mock/home/site-3.jpg",
  //   firstButtonLink: "/services-and-solutions",
  //   firstButtonText: "home-slider.slides.click_to_know_more",
  // },
  // {
  //   heading: "home-slider.slides.human",
  //   image: "/assets/images/mock/home/site-4.jpg",
  //   firstButtonLink: "/services-and-solutions/human_capital_development",
  //   firstButtonText: "home-slider.slides.capital_development",
  // },
  // {
  //   heading: "home-slider.slides.products",
  //   image: "/assets/images/mock/home/site-6.jpg",
  //   firstButtonLink: "/products",
  //   firstButtonText: "home-slider.slides.find_out_more",
  // },
  // ],
  services: {
    title: "home-services.title",
    services: [
      {
        en_title: "Cybersecurity",
        ar_title: "الأمن السيبراني",
        en_brief_description:
          "Offering world-class cybersecurity services and solutions to protect our clients' valuable assets through cybersecurity resilience and active defense services",
        ar_brief_description:
          "نقدم في سايت خدمات وحلول أمن سيبراني منافسة عالميًا لحماية الأصول التقنية الحساسة، بدءًا من خدمات الصمود السيبراني وصولاً إلى خدمات تعزيز الأمن السيبراني.",
        icon: "/assets/images/icon-advisory-services.svg",
        route: "/services-and-solutions/cybersecurity-services-and-solutions",
        id: "2",
      },
      {
        en_title: "Digital",
        ar_title: "الخدمات والحلول المؤسسية",
        en_brief_description:
          "Providing innovative and secure services and solutions to help digitize our clients' operations and achieve maximum efficiency and value.",
        ar_brief_description:
          "نقدّم حلولًا وخدمات مبتكرة وآمنة لمساعدة عملائنا في رحلة التحول الرقمي ورفع كفاءة الأعمال، بما في ذلك الحلول المؤسسية وخدمات التحليلات والبيانات الضخمة.",
        icon: "/assets/images/icon-digital-services.svg",
        route: "/services-and-solutions/enterprise-services-and-solutions",
        id: "4",
      },
      {
        en_title: "System Integration",
        ar_title: "الخدمات والحلول السحابية",
        en_brief_description:
          "Providing our clients with cloud services and solutions to help them in developing and operating applications to be hosted on SITE's secure cloud.",
        ar_brief_description:
          "نضع بين أيدي عملائنا حلول التقنية السحابية وخدماتها لنساعدهم على تطوير التطبيقات وتشغيلها ونقلها إلى بيئة سحابية آمنة.",
        icon: "/assets/images/icon-cybersecurity.svg",
        route: "/services-and-solutions/cloud-services-and-solutions",
        id: "5",
      },
      // {
      //   en_title: "Human Capital Development",
      //   ar_title: "تطوير رأس المال البشري",
      //   en_brief_description:
      //     "Developing human capabilities in digital solutions and cybersecurity through specialized services designed to boost skills and spur innovation.",
      //   ar_brief_description:
      //     "نحرص على تطوير الكفاءات البشرية الوطنية المتخصصة في مجال الحلول الرقمية والأمن السيبراني من خلال خدمات مصممة لتعزيز المهارات وتحفيز الابتكار.",
      //   icon: "/assets/images/icon-human-capital-development.svg",
      //   route: "/services-and-solutions/human-capital-development",
      //   id: "6",
      // },
    ],
  },
  products: {
    title: "home-products.title",
    description: "home-products.description",
    allProducts: "home-products.allProducts",
    default_description: "home-products.default",
    items: {},
  },
  certifications: {
    title: "home-certifications.title",
    description: "home-certifications.description",
    allCertifications: "home-certifications.allCertifications",
    default_description: "home-certifications.default",
    items: {},
  },
  achievements: {
    title: "home-achievements.our-achievements",
    counter_one_title: "home-achievements.partners",
    counter_second_title: "home-achievements.clients",
    counter_third_title: "home-achievements.services",
    counter_fourth_title: "home-achievements.projects",
    counter_one_content: "home-achievements.partnersMock",
    counter_second_content: "home-achievements.clientsMock",
    counter_third_content: "home-achievements.servicesMock",
    counter_fourth_content: "home-achievements.projectsMock",
  },
  feedback: {
    feedback: "home-feedback.feedback",
  },
  news: {},
  estimate: {
    title: "estimate-home.title",
    description: "estimate-home.desc",
    button: "estimate-home.button",
    link: "/contact-us",
  },
};
