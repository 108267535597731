export const MOCK = {
  slides: [
    {
      heading: "",
      subtitle: "",
      image: "",
      arabicFlipped: true,
    },
  ],
  description: "",
  links: [],

  breadcrumbs: [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/news",
      title: "breadcrumbs.news",
    },
  ],
};
