import { Component, OnInit } from "@angular/core";
import { MOCK } from "./mock";
import { ExecutiveManagementService } from "src/app/services/executive-management.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
@Component({
  selector: "app-executive-management",
  templateUrl: "./executive-management.component.html",
  styleUrls: ["./executive-management.component.scss"],
})
export class ExecutiveManagementComponent implements OnInit {
  mock = MOCK;
  isData = false;
  Executive_image = "assets/images/mock/executiveManagement/Executive.jpg";
  Board_image = "assets/images/mock/executiveManagement/Board.jpg";
  isBoard = true;
  constructor(
    private translate: TranslateService,
    private executiveManagementService: ExecutiveManagementService,
    private router: Router
  ) {}

  ngOnInit() {
    this.executiveManagementService
      .GetExecutiveManagement()
      .subscribe((members) => {
        this.mock.ExecutiveColumns = [];
        members.forEach((member) => {
          this.mock.ExecutiveColumns.push({
            icon: member.photo,
            title:
              this.translate.getDefaultLang() == "ar"
                ? member.ar_name
                : member.en_name,
            position:
              this.translate.getDefaultLang() == "ar"
                ? member.ar_position
                : member.en_position,
            content:
              this.translate.getDefaultLang() == "ar"
                ? member.ar_description
                : member.en_description,
            priority: member.priority,
          });
        });
        this.mock.reverseImage = false;
        this.mock.slides[0].image = this.Executive_image;
        this.mock.slides[0].heading = "about-executive-management.header";
        this.mock.breadcrumbs = [
          {
            link: "/",
            title: "Home",
          },
          {
            link: "/about-us",
            title: "about.title",
          },
          {
            link: "/about-us/executive-management",
            title: "about-executive-management.header",
          },
        ];
        this.isData = members.length > 0;
      });
    // this.executiveManagementService.GetBoardMembers().subscribe((members) => {
    //   this.mock.BoardColumns = [];
    //   members.forEach((member) => {
    //     this.mock.BoardColumns.push({
    //       icon: member.photo,
    //       title:
    //         this.translate.getDefaultLang() == "ar"
    //           ? member.ar_name
    //           : member.en_name,
    //       position:
    //         this.translate.getDefaultLang() == "ar"
    //           ? member.ar_position
    //           : member.en_position,
    //       content:
    //         this.translate.getDefaultLang() == "ar"
    //           ? member.ar_description
    //           : member.en_description,
    //       priority: member.priority,
    //     });
    //   });
    //   this.mock.reverseImage = true;
    //   this.mock.slides[0].image = this.Board_image;
    //   this.mock.slides[0].heading = "about-executive-management.board_header";
    //   this.mock.breadcrumbs = [
    //     {
    //       link: "/",
    //       title: "Home",
    //     },
    //     {
    //       link: "/about-us",
    //       title: "about.title",
    //     },
    //     {
    //       link: "/about-us/board-members",
    //       title: "about-executive-management.board_header",
    //     },
    //   ];
    //   this.isData = members.length > 0;
    // });
  }

  switchTab() {
    this.isBoard = !this.isBoard;
  }
}
