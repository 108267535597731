import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-columns-with-icon',
  templateUrl: './columns-with-icon.component.html',
  styleUrls: ['./columns-with-icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ColumnsWithIconComponent implements OnInit {
  @Input('columns') columns: Array<object>;
  @Input('fullWidth') fullWidth: boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
