import {
  Component,
  ViewEncapsulation,
  Input,
  ViewChild,
  ElementRef,
  PLATFORM_ID,
  Inject,
  OnInit,
} from "@angular/core";
import anime from "animejs/lib/anime.es.js";
import { isPlatformServer } from "@angular/common";
import Swiper from "swiper";
import { TranslateService } from "@ngx-translate/core";
import { ProductService } from "src/app/services/product.service";
import { Product } from "src/app/models/product";
import { Router } from "@angular/router";
import { AwardsAndCertificationsService } from "src/app/services/awards-and-certifications.service";
import { Award } from "src/app/models/award";

@Component({
  selector: "app-home-certifications",
  templateUrl: "./home-certifications.component.html",
  styleUrls: ["./home-certifications.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeCertificationsComponent implements OnInit {
  @Input("content") content: any;
  @ViewChild("productDesc", { static: false }) productDesc: ElementRef;
  @ViewChild("certificationSlider", { static: false })
  certificationSlider: ElementRef;

  public slider;
  awards: Array<Award>;
  list;

  public swiperOptions = {
    centeredSlides: true,
    slidesPerView: 1.5,
    centeredSlidesBounds: true,
    centerInsufficientSlides: true,
    spaceBetween: 20,
    loop: true,
    autoplay: {
      delay: 3000,
    },
    pagination: {
      el: ".certs-swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    // width: "500px",
    // innerWidth: "300px",
    breakpoints: {
      640: {
        slidesPerView: 1.5,
        // slidesPerGroup: 3,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1260: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
    },
  };
  activeIndex: any;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private awardsService: AwardsAndCertificationsService,

    @Inject(PLATFORM_ID) private platformId,
    private productService: ProductService
  ) {}

  ngOnInit() {
    this.awardsService.GetAwardsAndCertifications().subscribe((data) => {
      this.awards = data;
      this.handleSlider(data);
    });
  }

  handleSlider(awards) {
    awards.forEach((award, index) => {
      let arr = [];
      for (let value of awards) {
        const title = value.en_title.toLowerCase().split(" ").join("_");
        arr.push({
          title:
            this.translate.getDefaultLang() == "ar"
              ? value.ar_title
              : value.en_title,
          image: value.image,
        });
      }

      this.list = arr;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // if (!isPlatformServer(this.platformId) && this.isMobile()) {
      if (this.slider) {
        this.slider.destroy();
      }
      this.slider = new Swiper(
        this.certificationSlider.nativeElement,
        this.swiperOptions
      );
    }, 500);
  }

  isMobile() {
    if (!isPlatformServer(this.platformId)) {
      return window.innerWidth < 600 ? true : false;
    } else {
      return false;
    }
  }
}
