export const MOCK = {
  slides: [
    {
      heading: "header-slider.cybersecurity",
      image: '/assets/images/mock/contact/header-slide-1.jpg',
      subtitle: "Training program that ends in employment"
    }
  ],
  breadcrumbs: [
    {
      link: "/",
      title: "Home"
    },
    {
      link: "/join-us",
      title: "Join Us"
    },
    {
      link: "/join-us/programs",
      title: "SITE Programs"
    },
    {
      link: "/join-us/programs/cybersecurity-champions-program",
      title: "Cybersecurity Champions Program"
    }
  ],
  title: "Overview",
  description: "SITE Programs adopt a comprehensive and an intensive learning intervention that will equip Saudi talents with the knowledge, expertise, and necessary accreditation as well as improve professional capabilities through immersive training and hands-on exercises to meet Vision 2030 in digitization and Cybersecurity improvement initiatives. SITE programs programs aims to establish a strong relationship with SITE’s partner in the field of training, qualify the Saudi workforce and prepare participants to contribute to the satisfaction of business and mission needs and to have Impact on National Security.",
  title2: "Objective",
  description2: "To empower the next generation of innovators, developers, designers, data scientists, data analysts, and technology engineers by delivering a practical, innovative, reliable and career-focused program on state-of-the-art and next-generation technologies.",
  title3: "Program Target",
  description3: "To empower the next generation of innovators, developers, designers, data scientists, data analysts, and technology engineers by delivering a practical, innovative, reliable and career-focused program on state-of-the-art and next-generation technologies.",
  title4: "How to Apply?",
  description4: "To empower the next generation of innovators, developers, designers, data scientists, data analysts, and technology engineers by delivering a practical, innovative, reliable and career-focused program on state-of-the-art and next-generation technologies.",
  title5: "Can a Candidate Withdraw from the Program?",
  description5: "To empower the next generation of innovators, developers, designers, data scientists, data analysts, and technology engineers by delivering a practical, innovative, reliable and career-focused program on state-of-the-art and next-generation technologies.",
  image_with_content1: {
    image: '/assets/images/mock/join/image1.png',
    title: 'Training Methods',
    description: 'To empower the next generation of innovators, developers, designers, data scientists, data analysts, and technology engineers by delivering a practical, innovative, reliable and career-focused program on state-of-the-art and next-generation technologies.'
  },
  image_with_content2: {
    image: '/assets/images/mock/join/image3.png',
    title: 'Qualification Process',
    description: 'To empower the next generation of innovators, developers, designers, data scientists, data analysts, and technology engineers by delivering a practical, innovative, reliable and career-focused program on state-of-the-art and next-generation technologies.'
  },
  estimate: {
    title: "Want to be a part of it?",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    button: "Apply Now"
  },
  features: [
    {
      title: "test",
    },
    {
      title: "test",
    }
  ]

}