import { Component, OnInit } from '@angular/core';
import { MOCK } from './mock';

@Component({
  selector: 'app-cybersecurity',
  templateUrl: './cybersecurity.component.html',
  styleUrls: ['./cybersecurity.component.scss']
})
export class CybersecurityComponent implements OnInit {

  mock = MOCK;

  constructor() { }

  ngOnInit() {
  }

}
