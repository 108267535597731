export const MOCK = {
  heading: "header-slider.contact",
  slides: [
    {
      image: "/assets/images/mock/contact/header-slide-1.jpg",
      arabicFlipped: true,
    },
  ],
  breadcrumbs: [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/contact-us",
      title: "breadcrumbs.contact",
    },
  ],
  title: "contact.title",
  description: "contact.desc",
};
