import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { isPlatformServer } from '@angular/common';
import { LandingPageService } from './services/landingPage.service';
import { CookieService } from 'ngx-cookie-service';
import { Router, NavigationEnd } from '@angular/router';


export function getBaseLanguage(): string {
  let basePath: string = '/en';
  var userLang = navigator.language;
  userLang.includes('ar') ? basePath = '' : basePath = '';

  return basePath;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  public dir: string;
  public loadAPI: Promise<any>;
  url = "https://apis.google.com/js/client.js?onload=__onGoogleLoaded";
  isLandingPage = false;
  landingPageList = [];

  /**
   * .@ignore
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private translateService: TranslateService,
    private landingPageService: LandingPageService,  
    private cookieService: CookieService,
    private router: Router

  ) {
    router.events.subscribe((val) => {
      
      if(val instanceof NavigationEnd) {
        if(location.pathname=== "/"){
          this.router.navigate(["/" + this.translateService.currentLang], { replaceUrl: true });
          window.location.reload();
        }
        // see also 
        if(!window.location.pathname.startsWith("/en") && !window.location.pathname.startsWith("/ar")){
          this.router.navigate(["/" + this.translateService.currentLang + location.pathname], { replaceUrl: true });
          this.cookieService.set('lang', this.translateService.currentLang, 0, '/', window.location.host.toString().split(":")[0],true);
      }
      if(cookieService.get('lang').length < 1 ){
        this.cookieService.set('lang', this.translateService.currentLang , 0, '/', window.location.host.toString().split(":")[0],true);
      }
      if (location.pathname.startsWith("/ar") && this.cookieService.get('lang') === 'en') {
        this.translateService.use('ar')
        this.cookieService.set('lang', 'ar', 0, '/', window.location.host.toString().split(":")[0],true);

          window.location.reload();
      } else if (location.pathname.startsWith("/en") && this.cookieService.get('lang') === 'ar') {
        this.translateService.use('en')
        this.cookieService.set('lang', 'en', 0, '/', window.location.host.toString().split(":")[0],true);
          window.location.reload();
      }
    }
  });
  
    this.landingPageService.GetCampaignsTitles().subscribe(campaigns => {
      campaigns.forEach(element => {
        this.landingPageList.push(`/${element.en_campaign_name}`)
      });
      this.landingPageService.GetEventsTitles().subscribe(events => {
        events.forEach(element => {
          this.landingPageList.push(`/${element.event_name.toLowerCase()}`)
        });
        if (this.landingPageList.find((campaign) => window.location.href.includes(campaign.replace(" ", ""))))
          this.isLandingPage = true;
      });
    });
    let rtlLangs = ['ar'];

    this.dir = rtlLangs.indexOf(this.translateService.currentLang) > -1 ? 'rtl' : 'lrt';

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.dir = rtlLangs.indexOf(event.lang) > -1 ? 'rtl' : 'lrt';
    });

    if(cookieService.get('lang').length < 1 ){
      this.cookieService.set('lang', this.translateService.currentLang, 0, '/', window.location.host.toString().split(":")[0],true);

    }
    if(!window.location.pathname.startsWith("/en") && !window.location.pathname.startsWith("/ar")){
      this.router.navigate(["/" + this.translateService.currentLang + location.pathname], { replaceUrl: true });

    }
    
  }


  ngOnInit() { }
  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId)) {
      document.addEventListener("touchmove", function (event) {
        event.preventDefault();
      });
    }
  }

}
