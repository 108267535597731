import { Component, ElementRef, ViewChild, PLATFORM_ID, Inject, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { isPlatformServer } from '@angular/common';
import Swiper from 'swiper';

@Component({
  selector: 'app-services-slider',
  templateUrl: './services-slider.component.html',
  styleUrls: ['./services-slider.component.scss'],
})
export class ServicesSliderComponent  {

  @ViewChild('servicesSlider', {static: false}) servicesSlider: ElementRef;
  @Input('content') content: any;
  @Input('background') background: string = '#FFFFFF';
  @Input('index') index: string = '1';
  
  public slider;

  public swiperOptions = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    centeredSlides: true,
    centeredSlidesBounds: true,
    initialSlide: 1,
    navigation: {
      nextEl: '.services-slider__next',
      prevEl: '.services-slider__prev',
    },
    rtl: null
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private translateService: TranslateService,
  ) {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      setTimeout(() => {
        if (!isPlatformServer(this.platformId)) {
          if(this.slider) {
            this.slider.destroy();
          }
          this.swiperOptions.rtl = this.isRTL() ? true : false;
          this.slider = new Swiper(this.servicesSlider.nativeElement, this.swiperOptions);
        }
      })
    });
  }


  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId)) {
      setTimeout(() => {
        this.handleResize();

        this.swiperOptions.rtl = this.isRTL() ? true : false;

        this.slider = new Swiper(this.servicesSlider.nativeElement, this.swiperOptions);

        this.slider.on('slideChange', () => {
          if(this.slider.activeIndex== this.slider.slides.length-1)
          {
            setTimeout(() => {
              this.slider.slideTo(0, 600);
              this.slider.autoplay.start();
            }, 5000)
          }
        });
        }) 
    }
  }

  handleResize() {
    window.onresize = (e) => {
      if(this.slider) {
        this.slider.destroy();
      }


      this.slider = new Swiper(this.servicesSlider.nativeElement, this.swiperOptions);
    }
  }

  isRTL() {
    const app = document.querySelector('#app');
    return (app.getAttribute('dir') == 'rtl') ? true : false;
  }

}
