import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AboutUsRoute } from './routes/about-us';
import { LandingPageRoute } from './routes/LandingPage';
import { ContactRoute } from './routes/contact';
import { HomeRoute } from './routes/home';
import { JoinUsRoute } from './routes/join-us';
import { NewsRoute } from './routes/news';
import { PrivacyPolicyRoute } from './routes/privacy-policy';
import { PoliciesFormsRoute } from './routes/policies-and-forms';
import { SiteMapRoute } from './routes/sitemap';
// import { ReportsRoute } from './routes/reports';
import { ProductsRoute } from './routes/products';
import { ServicesAndSolutionsRoute } from './routes/services-and-solutions';
import { ErrorNotFoundRoute } from './routes/errorNotFound';
import { CookieService } from 'ngx-cookie-service';
import { ErrorNotFoundComponent } from './pages/error-not-found/error-not-found.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};


const routes: Routes = [

  
  {
    path: 'en',
    children: [
      HomeRoute,
      AboutUsRoute,
      ProductsRoute,
      NewsRoute,
      // ReportsRoute,
      ContactRoute,
      JoinUsRoute,
      ServicesAndSolutionsRoute,
      PrivacyPolicyRoute,
      PoliciesFormsRoute,
      SiteMapRoute,
      ErrorNotFoundRoute,
      LandingPageRoute,
      {
        path: '404',
        component: ErrorNotFoundComponent,
        data: {
          meta: {
            title: '404',
          }
        }
      },
    ]
  },
  {
    path: 'ar',
    children: [
      HomeRoute,
      AboutUsRoute,
      ProductsRoute,
      NewsRoute,
      // ReportsRoute,
      ContactRoute,
      JoinUsRoute,
      ServicesAndSolutionsRoute,
      PrivacyPolicyRoute,
      PoliciesFormsRoute,
      SiteMapRoute,
      ErrorNotFoundRoute,
      LandingPageRoute,
      {
        path: '404',
        component: ErrorNotFoundComponent,
        data: {
          meta: {
            title: '404',
          }
        }
      },
    ]
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],

})
export class AppRoutingModule { }
