import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MOCK } from './mock';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CookiesComponent implements OnInit {

  mock = MOCK;
  showCookie = true;
  showHint = false;
  id;
  constructor(private cookieService: CookieService,
    private translateService: TranslateService,
  ) {
    this.translateService.currentLang == 'ar' ? this.id = "cookie-policyAr" : this.id = "cookie-policy";
  }

  ngOnInit() {
    if (this.getCookie() != "shown") {
      this.showCookie = true;
      if (document.getElementById("cookie-banner")) {
        document.getElementById("cookie-banner").style.display = "flex";
      }

    }
  }

  getCookie(): string {
    return (this.cookieService.get('Cookie'));
  }

  setCookie() {
    this.cookieService.set('Cookie', 'shown', 0, '/', window.location.host.toString().split(":")[0], true);
  }


  openBanner() {
    document.getElementById("cookie-policy").style.display = "none";
    document.getElementById("cookie-banner").style.display = "flex";
  }

  closeBanner() {
    this.setCookie();
    document.getElementById("cookie-banner").style.display = "none";
    // Show Privacy-Policy button when policy agreement is accepted.
    // document.getElementById("cookie-policy").style.display = "flex";
  }

  openHint() {
    document.getElementById("cookie-hint").style.display = "flex";
  }

  closeHint() {
    document.getElementById("cookie-hint").style.display = "none";
  }

}
