import { ExecutiveManagementComponent } from "../pages/about-us/executive-management/executive-management.component";

export const ExecutiveManagementRoute = {
  path: 'executive-management',
  component: ExecutiveManagementComponent,
  data: {
    meta: {
      
    }
  }
}