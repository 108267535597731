import { Component, OnInit } from '@angular/core';
import { MOCK } from './mock';
import { ArticleService } from 'src/app/services/article.service';
import { Article } from 'src/app/models/article';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  mock = MOCK;

  isReports = window.location.pathname.includes('/reports')? true : false;
  articles: Array<Article>;

  constructor(
    private translate: TranslateService,
    private articleService: ArticleService
    ) { }
    
    ngOnInit() {
      if(this.isReports){
        this.articleService.GetReports().subscribe((data) => {
          this.articles = data;
          // this.handleReportsSlider(data);
        })
        
        this.mock.title = "breadcrumbs.reports"
        this.mock.breadcrumbs[1] = {
          link: "/reports",
          title: "breadcrumbs.reports"
        }
      }else{
        this.articleService.GetArticles().subscribe((data) => {
          this.articles = data;
          this.handleSlider(data);
        })
        
        this.mock.title = "news.latest"
        this.mock.breadcrumbs[1] = {
          link: "/news",
          title: "breadcrumbs.news"
        }
      }
  }

  handleSlider(articles) {
    this.mock.slides = [];
    articles.forEach((article, index) => {
      article.link = article.en_title.toLowerCase().replace(/ /g, '_').replace(/[^A-Za-z0-9_]/g, '');
      if (index < 3) {
        this.mock.slides.push({
          heading: (this.translate.getDefaultLang() == 'ar') ? article.ar_title : article.en_title,
          image: article.image,
          firstButtonLink: '/news/' + article.en_title.toLowerCase().replaceAll(' ', '_').replace(/[^A-Za-z0-9_]/g, ''),
          firstButtonText: 'home-news.read_more',
          arabicFlipped: true,
        });
      }
    });
  }

  handleReportsSlider(articles) {
    this.mock.slides = [];
    if(articles.length > 0){
      articles.forEach((article, index) => {
        if (index < 3) {
          this.mock.slides.push({
            heading: (this.translate.getDefaultLang() == 'ar') ? article.ar_title : article.en_title,
            image: article.image,
            arabicFlipped: true,
            document: (this.translate.getDefaultLang() == 'ar') ? article.ar_document : article.en_document,
          });
        }
      });
    }else{
      this.mock.slides.push({
        heading: "breadcrumbs.reports",
        image: "/assets/images/mock/static/about_us.jpg",
        arabicFlipped: true
      });
    }
  }

}
