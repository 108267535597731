import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { BusinessLineService } from "src/app/services/business-line.service";
import { BusinessLine } from "src/app/models/business_line";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
  @Input("dir") dir;
  public lang;
  public busineessLines: Array<BusinessLine>;
  XPlatform = "/assets/icons/XPlatform.svg";

  public year = new Date().getFullYear();
  copyRights;

  constructor(private businessLinesService: BusinessLineService) {}

  ngOnInit() {
    (this.copyRights =
      this.dir == "rtl"
        ? `جميع الحقوق محفوظة للشركة السعودية لتقنية المعلومات (سايت) © ${this.year}`
        : this.year + " © Saudi Information Technology Company"),
      this.businessLinesService.GetBusinessLines().subscribe((data) => {
        this.busineessLines = data;
      });
    window.location.href.startsWith("/en")
      ? (this.lang = "en")
      : (this.lang = "ar");
  }

  openSubmenu(ev) {
    ev.target.nextElementSibling.classList.toggle("active");
    ev.target.classList.toggle("active");
  }
}
