import { Component, OnInit, Input, ViewEncapsulation, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-curved-shape',
  templateUrl: './curved-shape.component.html',
  styleUrls: ['./curved-shape.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CurvedShapeComponent implements OnInit {

  @Input('position') position: any = {
    bottom: '-214px',
    top: 'auto',
    left: '-16px'
  };

  @Input('background') background: string = '#ffffff';
  @Input('index') index: string = '2';
  @Input('reverse') reverse: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
  ) {

  }

  ngOnInit() {

  }

  isRTL() {
    if (!isPlatformServer(this.platformId)) {
      const app = document.querySelector('#app');
      return (app.getAttribute('dir') == 'rtl') ? true : false;
    }

    return false;
  }

  isTablet() {
    const screenHeight = window.screen.height;
    return ((screenHeight <= 1025 && screenHeight >= 1024) || (screenHeight <= 769 && screenHeight >= 768) || (screenHeight <= 601 && screenHeight >= 600)) ? true : false;
  }

}
