import { MetaGuard } from '@ngx-meta/core';
import { NewsComponent } from '../pages/news/news.component';
import { ArticleRoute } from './article';

export const NewsRoute = {
  path: 'news',
  canActivateChild: [MetaGuard],
  children: [
    {
      path: '',
      component: NewsComponent,
    },
    ArticleRoute
  ],
  data: {
    meta: {
      title: 'News',
    }
  }
}