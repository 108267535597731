import { Component, OnInit, Input } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';
@Component({
  selector: 'app-content-tabs',
  templateUrl: './content-tabs.component.html',
  styleUrls: ['./content-tabs.component.scss']
})
export class ContentTabsComponent implements OnInit {

  @Input('tab1') tab1: string;
  @Input('tab2') tab2: string;
  @Input('tab3') tab3: string;

  @Input('tab1_anchor') tab1_anchor: string;
  @Input('tab2_anchor') tab2_anchor: string;
  @Input('tab3_anchor') tab3_anchor: string;

  active: number = 1;


  constructor() { }

  ngOnInit() {
  }

  chooseItem(item, anchor : string) {
    this.active = item;

    const { top } = document.querySelector(anchor).getBoundingClientRect()
    const navHeight = 110
    const scrollCoords = {
      y: window.pageYOffset
    }

    anime({
      targets: scrollCoords,
      y: ((top + window.pageYOffset) - navHeight),
      duration: 500,
      easing: 'easeInOutCubic',
      update: () => window.scroll(0, scrollCoords.y)
    })
  }

}
