import { Component, OnInit, Input, ViewEncapsulation, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-event_registration',
  templateUrl: './event_registration.component.html',
  styleUrls: ['./event_registration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EventRegistrationTemplateComponent implements OnInit {
  @Input('data') data;
  @Input('style') style;
  @Input('contactForm') contactForm;
  location_icon = "assets/icons/location_icon.svg"
  calender_icon = "assets/icons/date_icon.svg"
  clock_icon = "assets/icons/time_icon.svg"
  width = window.screen.width;
  isSubmitted = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
  ) { }

  ngOnInit() {
    // document.documentElement.style.setProperty('--new_color', this.style.background_color)

   }
  successMessage($event) {
    this.isSubmitted = $event;
  }
  isRTL() {
    if (!isPlatformServer(this.platformId)) {
      const app = document.querySelector('#app');
      return (app.getAttribute('dir') == 'rtl') ? true : false;
    }

    return false;
  }
}
