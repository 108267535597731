import { Routes } from '@angular/router';
import { MetaGuard } from '@ngx-meta/core';

//Routes
import { HomeRoute } from './home';
import { AboutUsRoute } from './about-us';
import { ProductsRoute } from './products';
import { NewsRoute } from './news';
import { ContactRoute } from './contact';
import { JoinUsRoute } from './join-us';
import { ServicesAndSolutionsRoute } from './services-and-solutions';
import { ErrorNotFoundComponent } from '../pages/error-not-found/error-not-found.component';
import { PrivacyPolicyRoute } from './privacy-policy';
import { PoliciesFormsRoute } from './policies-and-forms';
import { SiteMapRoute } from './sitemap';
import { LandingPageRoute } from './LandingPage';
import { ErrorNotFoundRoute } from './errorNotFound';
// import { ReportsRoute } from './reports';

export const routes: Routes = [
  {
    path: 'ar',
    canActivateChild: [MetaGuard],
    children: [
      HomeRoute,
      AboutUsRoute,
      ProductsRoute,
      NewsRoute,
      // ReportsRoute,
      ContactRoute,
      JoinUsRoute,
      ServicesAndSolutionsRoute,
      PrivacyPolicyRoute,
      PoliciesFormsRoute,
      SiteMapRoute,
      ErrorNotFoundRoute,
      LandingPageRoute,
      {
        path: '404',
        component: ErrorNotFoundComponent,
        data: {
          meta: {
            title: '404',
          }
        }
      },
    ]
  },
  {
    path: 'en',
    canActivateChild: [MetaGuard],
    children: [
      HomeRoute,
      AboutUsRoute,
      ProductsRoute,
      NewsRoute,
      // ReportsRoute,
      ContactRoute,
      JoinUsRoute,
      ServicesAndSolutionsRoute,
      PrivacyPolicyRoute,
      PoliciesFormsRoute,
      SiteMapRoute,
      ErrorNotFoundRoute,
      LandingPageRoute,
      {
        path: '404',
        component: ErrorNotFoundComponent,
        data: {
          meta: {
            title: '404',
          }
        }
      },
    ]
  },
  {
    path: '',
    canActivateChild: [MetaGuard],
    children: [
      HomeRoute,
      AboutUsRoute,
      ProductsRoute,
      NewsRoute,
      // ReportsRoute,
      ContactRoute,
      JoinUsRoute,
      ServicesAndSolutionsRoute,
      PrivacyPolicyRoute,
      PoliciesFormsRoute,
      SiteMapRoute,
      ErrorNotFoundRoute,
      LandingPageRoute,
      {
        path: '404',
        component: ErrorNotFoundComponent,
        data: {
          meta: {
            title: '404',
          }
        }
      },
      {
        path: '**',
        redirectTo: '/404'
      }
    ]
  }
];
