import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";

@Component({
  selector: "app-awards-list",
  templateUrl: "./awards-list.component.html",
  styleUrls: ["./awards-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AwardsListComponent implements OnInit {
  @Input("content") content;

  constructor() {}

  ngOnInit() {}
}
