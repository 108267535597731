import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { style } from "./style";

@Component({
  selector: "app-contact-map",
  templateUrl: "./contact-map.component.html",
  styleUrls: ["./contact-map.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ContactMapComponent implements OnInit {
  coordinates = new google.maps.LatLng(24.742927, 46.6320923);

  options: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 12,
    styles: style,
  };

  marker = {
    position: {
      lat: 24.742927,
      lng: 46.6320923,
    },
    label: {
      color: "Green",
      text: "Marker label ",
    },
    title: "Marker title ",
  };

  constructor() {}

  ngOnInit() {}
}
