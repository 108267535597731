import { ServicesAndSolutionsComponent } from "../pages/services-and-solutions/services-and-solutions.component";
import { MetaGuard } from '@ngx-meta/core';
import { ServiceRoute } from './service';

export const ServicesAndSolutionsRoute = {
  path: 'services-and-solutions',
  canActivateChild: [MetaGuard],
  children: [
    {
      path: '',
      component: ServicesAndSolutionsComponent,
    },
    ServiceRoute
  ],
  data: {
    meta: {
      title: 'Services & Solutions',
    }
  }
}