export const MOCK = {
  slides: [
    {
      heading: '',
      subtitle: '',
      image: '/assets/images/mock/product/header-slide-1.jpg',
      headingIcon: '',
      slogan:'',
      firstButtonText: '',
      firstButtonLink: '',
      secondButtonText: '',
      secondButtonLink: '',
    }
  ],
  breadcrumbs: [
    {
      link: "/",
      title: "Home"
    },
    {
      link: "/services-and-solutions",
      title: "breadcrumbs.services"
    },
  ],
  title: "",
  description: 'SITE Analyze is an online platform that detects vulnerabilities in web applications which may get exploited by attackers. In addition, this platform provides a vulnerability management system to keep track of the existing vulnerabilities.',
  estimate: {
    title: "estimate.title",
    description: "estimate.desc",
    button: "estimate.button",
    link: 'https://site.sa/contact-us'
  },
    sub_services:[],
    info: []

}