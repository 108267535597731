import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ContactRelationService } from "src/app/services/contact-relation.service";
import { ContactSubjectService } from "src/app/services/contact-subject.service";
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ContactService } from "src/app/services/contact.service";
import anime from "animejs/lib/anime.es.js";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ContactFormComponent implements OnInit {
  @ViewChild("contactElement", { static: false }) contactElement: ElementRef;
  relations;
  subjects;
  subjectCategories = [];
  submitted = false;
  lang = "en";
  isScroll = false;
  recapchaKey = environment.RECAPCHA_KEY;
  isRequried = false;
  emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  constructor(
    private contactRelationService: ContactRelationService,
    private contactSubjectService: ContactSubjectService,
    private contactService: ContactService,
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private translate: TranslateService
  ) {}

  contactForm = this.fb.group({
    name: new FormControl("", [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(/^[\p{L}\p{M}\s\u0600-\u06FF]+$/u),
    ]),
    email: new FormControl("", [
      Validators.required,
      Validators.email,
      Validators.pattern(this.emailRegex),
    ]),
    contact_number: new FormControl("", [
      Validators.required,
      Validators.maxLength(15),
      Validators.minLength(10),
      Validators.pattern(/^[\s()+-]*([0-9][\s()+-]*){6,20}$/),
    ]),
    company: new FormControl("", [
      Validators.required,
      Validators.maxLength(100),
    ]),
    contact_subject: new FormControl("", [Validators.required]),
    contact_subject_category: new FormControl(),
    message: new FormControl("", [
      Validators.required,
      Validators.maxLength(500),
    ]),
    lang: new FormControl(""),
    recaptcha: new FormControl("", [Validators.required]),
  });

  form = this.fb.group({
    name: new FormControl("", [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(/^[\p{L}\p{M}\s\u0600-\u06FF]+$/u),
    ]),
    email: new FormControl("", [
      Validators.required,
      Validators.email,
      Validators.pattern(this.emailRegex),
    ]),
    contact_number: new FormControl("", [
      Validators.required,
      Validators.maxLength(15),
      Validators.minLength(10),
      Validators.pattern(/^[\s()+-]*([0-9][\s()+-]*){6,20}$/),
    ]),
    company: new FormControl("", [
      Validators.required,
      Validators.maxLength(100),
    ]),
    contact_subject: new FormControl(),
    contact_subject_category: new FormControl(),
    message: new FormControl("", [
      Validators.required,
      Validators.maxLength(500),
    ]),
    lang: new FormControl(),
    recaptcha: new FormControl("", [Validators.required]),
  });

  ngOnInit() {
    this.lang = this.translate.getDefaultLang();
    // this.contactForm.patchValue({country: (this.translate.getDefaultLang() == 'ar') ? this.countryList[0].ar_country : this.countryList[0].en_country});

    this.contactRelationService.GetRelations().subscribe((data) => {
      this.relations = data;
      // this.contactForm.patchValue({contact_relation: this.relations[0].id})
    });

    this.contactSubjectService.GetSubjects().subscribe((data) => {
      this.subjects = data;
      // this.contactForm.patchValue({contact_subject: this.subjects[0].id})
      // this.getRelations(this.contactForm.get('contact_subject').value); // TEST ISSUE
    });
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  subjectChange(ev) {
    this.getRelations(this.contactForm.get("contact_subject").value);
  }

  getRelations(subject) {
    this.subjects.forEach((element) => {
      if (element.id == subject && element.subject_category.length > 0) {
        this.subjectCategories = element.subject_category;
        this.contactForm.patchValue({
          contact_subject_category: this.subjectCategories[0].id,
        });
      } else if (element.id == subject) {
        this.subjectCategories = [];
        this.contactForm.patchValue({ contact_subject_category: 1 });
      }
    });
  }

  onSubmit() {
    this.isScroll = false;
    const subject =
      this.contactForm.value.contact_subject &&
      this.subjects.find(
        (subject) =>
          subject.id === Number(this.contactForm.value.contact_subject)
      ).en_subject;
    let subject_category =
      this.contactForm.value.contact_subject_category &&
      this.subjectCategories.find(
        (category) =>
          category.id ===
          Number(this.contactForm.value.contact_subject_category)
      );

    subject_category = subject_category ? subject_category.en_category : "-";
    console.log("valid: ", this.contactForm);

    if (this.contactForm.valid) {
      this.form.setValue({
        contact_subject: subject,
        contact_subject_category: subject_category,
        name: this.contactForm.value.name,
        email: this.contactForm.value.email,
        contact_number: this.contactForm.value.contact_number,
        company: this.contactForm.value.company,
        message: this.contactForm.value.message,
        lang: this.translate.currentLang,
        recaptcha: this.contactForm.value.recaptcha,
      });

      console.log("this: ", this.contactForm, this.contactForm.value);

      this.spinner.show();
      this.contactService.Contact(this.form.value).subscribe(
        () => {
          setTimeout(() => {
            this.submitted = true;
            this.spinner.hide();

            const { top } =
              this.contactElement.nativeElement.getBoundingClientRect();
            const navHeight = 150;
            const scrollCoords = {
              y: window.pageYOffset,
            };

            anime({
              targets: scrollCoords,
              y: top + window.pageYOffset - navHeight,
              duration: 500,
              easing: "easeInOutCubic",
              update: () => window.scroll(0, scrollCoords.y),
            });
          }, 2000);
        },
        (error) => {
          if (Object.keys(error)[0] == "contact_number") {
            this.spinner.hide();
            this.contactForm.controls["contact_number"].setErrors({
              pattern: {
                actualValue: "16019521325",
                requiredPattern: "/^\\+[1-9]\\d{1,14}$/",
              },
            });
          }
        }
      );
    } else {
      this.validateAllFormFields(this.contactForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    this.isRequried = true;
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        if (control.status === "INVALID") {
          if (!this.isScroll) {
            var firstInvalid = document.getElementById(field);
            if (firstInvalid) {
              this.isScroll = true;
              firstInvalid.focus();
            }
          }
        }
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  reset() {
    this.contactForm.reset();
  }
}
