import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-flexible-boxes',
  templateUrl: './boxes.component.html',
  styleUrls: ['./boxes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BoxesComponent implements OnInit {
  @Input('content') content: Array<object>;
  @Input('title') title: string;

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
  }
}
