import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { BusinessLine } from '../models/business_line'; //todo: change "any" to new model


@Injectable({
  providedIn: 'root'
})
export class SubserviceService {


  constructor(private http: HttpClient) { }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
//sub_service_detail/?title=OT%20Custom%20page
//sub_service_list/
//sub_service/<pk>
  // GET
  GetSubservices(): Observable<any[]> {
    return this.http.get<any[]>(environment.backendUrl + '/sub_service_list/')
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  // GET
  GetSubservice(id): Observable<any> {
    return this.http.get<any>(environment.backendUrl + '/sub_service/' + id + '')
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  // Error handling
  errorHandl(error) {
      let errorMessage = '';
      if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      return throwError(errorMessage);
  }


}
