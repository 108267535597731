import {
  Component,
  ViewEncapsulation,
  PLATFORM_ID,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { isPlatformServer } from "@angular/common";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  @ViewChild("header", { static: false }) header: ElementRef;
  @ViewChild("mobileButton", { static: false }) mobileButton: ElementRef;
  // @ViewChild('mySiteButton', {static: false}) mySiteButton: ElementRef;

  public stuck = false;
  public stickPoint;
  public lang;
  logo = "assets/icons/site_logo.svg";
  mySite = "/assets/images/mySITE_icon.svg";
  constructor(@Inject(PLATFORM_ID) private platformId) {}

  ngOnInit() {
    window.location.pathname.startsWith("/en")
      ? (this.lang = "en")
      : (this.lang = "ar");
  }

  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId)) {
      this.handleScroll();
      this.stickPoint = this.getDistance();
    }
    window.location.pathname.startsWith("/en")
      ? (this.lang = "en")
      : (this.lang = "ar");
  }

  handleScroll() {
    window.onscroll = () => {
      const distance = this.getDistance() - window.pageYOffset;
      const offset = window.pageYOffset;
      if (this.header) {
        if (distance < 0 && !this.stuck) {
          this.header.nativeElement.style.position = "fixed";
          this.header.nativeElement.style.top = "0px";
          this.header.nativeElement.classList.add("header__active");
          document.body.classList.add("menu-fixed");
          this.stuck = true;
        } else if (this.stuck && offset <= this.stickPoint) {
          this.header.nativeElement.classList.remove("header__active");
          document.body.classList.remove("menu-fixed");
          this.header.nativeElement.style.position = "relative";
          this.stuck = false;
        }
      }
    };
  }

  getDistance() {
    return this.header ? this.header.nativeElement.offsetTop : null;
  }

  redirectTo(url) {
    window.open(url, "_blank");
  }

  toggleMySiteMenu() {
    const icon = document.querySelector(".header__mySiteButton");
    const menu = document.querySelector(".header__submenu");
    if (menu.classList.contains("header__submenu--opened")) {
      icon.classList.remove("header__mySiteButton--open");
      menu.classList.remove("header__submenu--opened");
    } else {
      icon.classList.add("header__mySiteButton--open");
      menu.classList.add("header__submenu--opened");
    }
  }

  toggleMobileMenu() {
    //mySite
    // const mySiteMenu = document.querySelector(".header__submenu");
    // const icon = document.querySelector(".header__mySiteButton");
    // if (mySiteMenu.classList.contains("header__submenu--opened")) {
    //   mySiteMenu.classList.remove("header__submenu--opened");
    //   icon.classList.remove("header__mySiteButton--open");
    // }

    const menu = document.querySelector(".header-nav__menu");
    const header = document.querySelector(".header");

    if (menu.classList.contains("header-nav__menu__opened")) {
      this.closeMobileMenu(menu, header);
    } else {
      this.openMobileMenu(menu, header);
    }
  }

  closeMobileMenu(menu, header) {
    if (window.innerWidth < 1025) {
      const body = document.querySelector("body");
      menu.classList.remove("header-nav__menu__opened");
      header.classList.remove("header__menuOpened");
      body.classList.remove("menuOpened");
      this.mobileButton.nativeElement.classList.remove(
        "header__mobileButton--open"
      );
    }
  }

  openMobileMenu(menu, header) {
    if (window.innerWidth < 1025) {
      const body = document.querySelector("body");
      menu.classList.add("header-nav__menu__opened");
      header.classList.add("header__menuOpened");
      body.classList.add("menuOpened");
      this.mobileButton.nativeElement.classList.add(
        "header__mobileButton--open"
      );
    }
  }
}
