import { Component } from "@angular/core";
import { MOCK } from "./mock";
import { MetaService } from "@ngx-meta/core";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BusinessLineService } from "src/app/services/business-line.service";
import { SubserviceService } from "src/app/services/subservice.service";

@Component({
  selector: "app-service",
  templateUrl: "./service.component.html",
  styleUrls: ["./service.component.scss"],
})
export class ServiceComponent {
  slug;
  title;
  id;
  description;
  mock = MOCK;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private businessLineService: BusinessLineService,
    private SubserviceService: SubserviceService,
    private meta: MetaService
  ) {}

  ngOnInit() {
    this.route.params.forEach((param) => {
      this.slug = param.slug;
      this.businessLineService.GetBusinessLines().subscribe((lines) => {
        const tempData = lines.filter(
          (a) => a.en_title.toLowerCase().split(" ").join("_") == this.slug
        );
        // tempData[0].id ? this.id = tempData[0].id : this.id = 1;
        if (tempData.length < 1) {
          this.router.navigate([`/${this.translate.currentLang}/404`]);
          return;
        }
        this.id = tempData[0].id;
        this.businessLineService.GetBusinessLine(this.id).subscribe((data) => {
          this.title =
            this.translate.getDefaultLang() == "ar"
              ? data.ar_title
              : data.en_title;
          this.description =
            this.translate.getDefaultLang() == "ar"
              ? data.ar_description
              : data.en_description;

          this.meta.setTitle(this.title);
          this.meta.setTag("description", this.description);
          this.meta.setTag("og:image", data.image);

          this.mock.description = this.description;

          this.mock.image = data.image;
          this.mock.heading = this.title;
          this.mock.subtitle =
            this.translate.getDefaultLang() == "ar"
              ? data.ar_slogan
              : data.en_slogan;

          if (this.mock.breadcrumbs.length == 3) {
            this.mock.breadcrumbs.pop();
          }

          this.mock.main_services = data.business_line_sections;

          let services = [];

          data.business_line_sections.forEach((value: any) => {
            let main = {
              title:
                this.translate.getDefaultLang() == "ar"
                  ? value.ar_title
                  : value.en_title,
              description:
                this.translate.getDefaultLang() == "ar"
                  ? value.ar_description
                  : value.en_description,
              image: value.image,
              items: [],
            };

            value.services.forEach((service) => {
              let url = "";
              // if (service.sub_service !== null) {
              //   this.SubserviceService.GetSubservice(
              //     service.sub_service
              //   ).subscribe((u) => {
              //     url =
              //       "/services-and-solutions/" +
              //       this.slug +
              //       "/" +
              //       u.en_title.toLowerCase().split(" ").join("_");

              let products = [];
              let productsList = [
                {
                  en_title: "site_intelligence",
                  title: "Intelligence (Threat Intelligence Platform)​​",
                },
                {
                  en_title: "site_inspect",
                  title: "Inspect (File and URL Analysis Platform)",
                },
                {
                  en_title: "site_observe",
                  title: "Observe (Brand Protection Platform) ​​",
                },
              ];

              if (service.en_title === "Threat intelligence") {
                productsList.forEach((product) => {
                  products.push({
                    title: product.title,
                    route:
                      "/products/" +
                      product.en_title.toLowerCase().split(" ").join("_"),
                  });
                });

                service.products = products;
                main.items.push({
                  title:
                    this.translate.getDefaultLang() == "ar"
                      ? service.ar_title
                      : service.en_title,
                  description:
                    this.translate.getDefaultLang() == "ar"
                      ? service.ar_description
                      : service.en_description,
                  icon: service.icon,
                  sub_url: service.sub_service,
                  products: products,
                });
              } else {
                if (
                  service.en_title === "Managed cybersecurity system (MSSP)"
                ) {
                  // let sub_services = [
                  //   {
                  //     en_title: "Managed EDR service.",
                  //     ar_title: "خدمة EDR المُدارة",
                  //   },
                  //   {
                  //     en_title: "Managed SIEM service.​",
                  //     ar_title: "خدمة SIEM المُدارة",
                  //   },
                  //   {
                  //     en_title: "Managed NTD service.​",
                  //     ar_title: "خدمة NTD المُدارة",
                  //   },
                  // ];
                  let en_sub_services = [
                    "Managed EDR service.",
                    "Managed SIEM service.​",
                    "Managed NTD service.​",
                  ];
                  let ar_sub_services = [
                    "خدمة EDR المُدارة",
                    "خدمة SIEM المُدارة",
                    "خدمة NTD المُدارة",
                  ];
                  main.items.push({
                    title:
                      this.translate.getDefaultLang() == "ar"
                        ? service.ar_title
                        : service.en_title,
                    description:
                      this.translate.getDefaultLang() == "ar"
                        ? service.ar_description
                        : service.en_description,
                    icon: service.icon,
                    sub_url: service.sub_service,
                    sub_services:
                      this.translate.getDefaultLang() == "ar"
                        ? ar_sub_services
                        : en_sub_services,
                  });
                } else {
                  main.items.push({
                    title:
                      this.translate.getDefaultLang() == "ar"
                        ? service.ar_title
                        : service.en_title,
                    description:
                      this.translate.getDefaultLang() == "ar"
                        ? service.ar_description
                        : service.en_description,
                    icon: service.icon,
                    sub_url: service.sub_service,
                  });
                }
              }

              // });
              // } else {
              //   main.items.push({
              //     title:
              //       this.translate.getDefaultLang() == "ar"
              //         ? service.ar_title
              //         : service.en_title,
              //     description:
              //       this.translate.getDefaultLang() == "ar"
              //         ? service.ar_description
              //         : service.en_description,
              //     icon: service.icon,
              //     sub_url: null,
              //   });
              // }
            });

            services.push(main);
            console.log("services", services);
          });

          this.mock.main_services = services;
          this.mock.breadcrumbs.push({
            link: "/services-and-solutions/" + this.slug,
            title: this.title,
          });
        });
      });
    });
  }

  getURL = async (sub_id) => {
    await this.SubserviceService.GetSubservice(sub_id).subscribe(
      (u) =>
        "/services-and-solutions/" +
        this.slug +
        "/" +
        u.en_title.toLowerCase().split(" ").join("_")
    );
  };
}
