import { ProductRoute } from './product';
import { MetaGuard } from '@ngx-meta/core';
import { ProductsComponent } from '../pages/products/products.component';

export const ProductsRoute = {
  path: 'products',
  canActivateChild: [MetaGuard],
  children: [
    {
      path: '',
      component: ProductsComponent,
    },
    ProductRoute
  ],
  data: {
    meta: {
      title: 'Products',
    }
  }
}