import { Component, ViewChild, ElementRef, PLATFORM_ID, Inject, Input } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import anime from 'animejs/lib/anime.es.js';
declare var VanillaTilt;

@Component({
  selector: 'app-animation-circle',
  templateUrl: './circle.component.html',
  styleUrls: ['./circle.component.scss']
})
export class CircleComponent  {
  @ViewChild('circle', {static: false}) circle: ElementRef;
  @ViewChild('circleOne', {static: false}) circleOne: ElementRef;
  @ViewChild('circleTwo', {static: false}) circleTwo: ElementRef;
  @ViewChild('icon', {static: false}) icon: ElementRef;
  @Input('icon') iconHref: String;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
  ) { }

  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId)) {
      // VanillaTilt.init(this.circle.nativeElement, {
      //   max: 15,
      //   speed: 400,
      // });

      anime({
        targets: [this.circleTwo.nativeElement],
        rotate: -360,
        easing: 'linear',
        duration: 500,
        complete: () => {
          anime({
            targets: [this.icon.nativeElement],
            opacity: 1,
            easing: 'easeInOutQuad'
          });
        }
      })

      

    }
  }

}
