import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-company-address",
  templateUrl: "./company-address.component.html",
  styleUrls: ["./company-address.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CompanyAddressComponent implements OnInit {
  addressInfo = [
    "6550 Almuhir (Digital City),\n Unit No. 8, An Nakheel District \nRiyadh City 12382-3711, Saudi Arabia",
    "T: +966118131100 \n  F: +966118131101",
  ];

  constructor() {}

  ngOnInit() {}
}
