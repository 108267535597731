import { Component, ViewEncapsulation, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingPageService } from 'src/app/services/landingPage.service';
import { MOCK } from './mock';

@Component({
  selector: 'app-campaign',
  templateUrl: './LandingPage.component.html',
  styleUrls: ['./LandingPage.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingPageComponent {
  public slug;
  public title: string;
  public subtitle: string;
  public description: string;
  public details_title: string;
  public CTA_Button: string;
  mock = MOCK;
  landingPageObj;
  landingPageType;
  isLandingPage;
  pageLang;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private landingPageService: LandingPageService,
    ) {
    this.pageLang = this.router.url.includes('/ar/')? 'ar' : 'en';
    this.landingPageService.GetCampaignsDetails().subscribe(campaigns => {
      const slug = this.router.url.replace('/en/', '').replace('/ar/', '');
      for (let i = 0; i < campaigns.length; i++) {
        const campaign_name = campaigns[i].en_campaign_name.replaceAll(" ", "");
        if (campaign_name.includes(slug)) {
          this.landingPageObj = campaigns[i];
          this.landingPageType = campaigns[i].template;
          this.getCampaignData(this.landingPageObj);
        }
        if (this.isLandingPage) break;
      }

      this.landingPageService.GetEventsDetails().subscribe(events => {

        const slug = this.router.url.replace('/en/', '').replace('/ar/', '');
        if (!this.isLandingPage) {
          for (let i = 0; i < events.length; i++) {
            const event_name = events[i].event_name.replaceAll(" ", "");
            if (event_name.toLowerCase() === slug) {
              this.landingPageObj = events[i];
              this.landingPageType = 'eventTemplate';
              this.getEventData(this.landingPageObj);
            }
            if (this.isLandingPage) break;
          }
        }


        if (this.isLandingPage) {
          if (this.router.url.includes("/en/") && this.cookieService.get('lang') !== 'en') {
            this.cookieService.set('lang', 'en', 0, '/', window.location.host.toString().split(":")[0],true);
            this.router.navigate([this.router.url])
              .then(() => {
                window.location.reload();
              });
          } else if (!this.router.url.includes("/en/") && this.cookieService.get('lang') !== 'ar') {
            this.cookieService.set('lang', 'ar', 0, '/', window.location.host.toString().split(":")[0],true);
            this.router.navigate([this.router.url])
              .then(() => {
                window.location.reload();
              });
          }
        } else if (!this.router.url.includes('/404')) {
          this.isLandingPage = false;
          this.router.navigate([`/${this.pageLang}/404`])
          // .then(() => {
          //   window.location.reload();
          // });
        }
      })




    });
  }

  ngOnInit() {




  }

  isRTL() {
    if (!isPlatformServer(this.platformId)) {
      const app = document.querySelector('#app');
      return (app.getAttribute('dir') == 'rtl') ? true : false;
    }

    return false;
  }

  getEventData(data): void {
    this.mock.eventData.event_name = data.event_name;
    this.mock.eventData.title = this.pageLang === 'en' ? data.en_title : data.ar_title;
    this.mock.eventData.description = this.pageLang === 'en' ? data.en_description : data.ar_description;
    this.mock.eventData.subtitle = this.pageLang === 'en' ? data.en_subtitle : data.ar_subtitle;;
    this.mock.eventData.location = this.pageLang === 'en' ? data.en_location : data.ar_location;
    this.mock.eventData.time = this.pageLang === 'en' ? data.en_time : data.ar_time;
    this.mock.eventData.google_maps_url = data.google_maps_url;
    this.mock.eventData.date = data.date;
    this.mock.eventData.url = data.url;
    this.mock.eventData.sponsors = data.sponsors.map((item) => {
      return {
        logo: item.logo,
        url: item.url
      }
    })

    this.mock.eventStyle.title_color = data.title_color;
    this.mock.eventStyle.description_color = data.description_color;
    this.mock.eventStyle.subtitle_color = data.subtitle_color;
    this.mock.eventStyle.location_color = data.location_color;
    this.mock.eventStyle.time_color = data.time_color;
    this.mock.eventStyle.date_color = data.date_color;
    this.mock.eventStyle.background_color = data.background_color;
    this.mock.eventStyle.background_large = data.background_large;
    this.mock.eventStyle.background_laptop = data.background_laptop;
    this.mock.eventStyle.background_mobile = data.background_mobile;
    this.mock.eventStyle.logo = data.logo;

    this.mock.eventContactForm.CTA = this.pageLang === 'en' ? data.en_CTA : data.ar_CTA;
    this.mock.eventContactForm.file_attachment = data.file_attachment;
    this.mock.eventContactForm.CTA_text_color = data.CTA_text_color;
    this.mock.eventContactForm.CTA_button_color = data.CTA_button_color;
    this.mock.eventContactForm.email_Subject = data.email_Subject;
    this.mock.eventContactForm.thx_msg = this.pageLang === 'en' ? data.en_thx_msg : data.ar_thx_msg;
    this.mock.eventContactForm.thx_msg_color = data.thx_msg_color;
    this.mock.eventContactForm.more_info = this.pageLang === 'en' ? data.en_more_info : data.ar_more_info;
    this.mock.eventContactForm.more_info_color = data.more_info_color;
    this.mock.eventContactForm.more_info_url_title = this.pageLang === 'en' ? data.en_more_info_url_title : data.ar_more_info_url_title;
    this.mock.eventContactForm.more_info_url = data.more_info_url;
    this.mock.eventContactForm.more_info_url_color = data.more_info_url_color;



    this.isLandingPage = true;
  }


  getCampaignData(data): void {
    let details;
    details = "<ul>";
    data.campaign_detail.forEach(element => {
      (this.router.url.includes(`/en/${this.slug}`)) ? details = details + `<li>${element.en_description}</li>` : details = details + `<li>${element.ar_description}</li>`;
    });
    details = details + "</ul>";

    let features;
    features = "<ul>";
    data.campaign_feature.forEach(element => {
      (this.router.url.includes(`/en/${this.slug}`)) ? features = features + `<li>${element.en_description}</li>` : features = features + `<li>${element.ar_description}</li>`;
    });
    features = features + "</ul>";

    this.mock.campaignData.title = this.pageLang === 'en' ? data.en_title : data.ar_title;
    this.mock.campaignData.subtitle = this.pageLang === 'en' ? data.en_subtitle : data.ar_subtitle;
    this.mock.campaignData.description = this.pageLang === 'en' ? data.en_description : data.ar_description;
    this.mock.campaignData.details_title = this.pageLang === 'en' ? data.en_description : data.ar_description
    this.mock.campaignData.details = details;
    this.mock.campaignData.features = features;
    this.mock.campaignData.CTA_Button = this.pageLang === 'en' ? data.en_CTA : data.ar_CTA;
    this.mock.campaignData.emailSubject = data.email_Subject;

    this.mock.campaignStyle.subtitle_color = data.subtitle_color;
    this.mock.campaignStyle.description_color = data.description_color;
    this.mock.campaignStyle.detailsTitle_color = data.detailsTitle_color;
    if (data.en_detailsImg) this.mock.campaignStyle.detailsImg = `url('${data.en_detailsImg}')`;
    if (data.en_detailsImgPhone) this.mock.campaignStyle.detailsImgPhone = `url('${data.en_detailsImgPhone}')`;
    if (data.ar_detailsImg) this.mock.campaignStyle.detailsImgAr = `url('${data.ar_detailsImg}')`;
    if (data.ar_detailsImgPhone) this.mock.campaignStyle.detailsImgPhoneAr = `url('${data.ar_detailsImgPhone}')`;
    this.mock.campaignStyle.features_background_color = data.feature_background_color;
    this.mock.campaignStyle.features_color = data.feature_color;
    this.mock.campaignStyle.header_large = `url('${data.header_large}')`;
    this.mock.campaignStyle.header_laptop = `url('${data.header_laptop}')`;
    this.mock.campaignStyle.header_phone = `url('${data.header_mobile}')`;
    this.mock.campaignStyle.logo = data.logo;
    this.mock.campaignStyle.background_color = data.background_color;

    this.isLandingPage = true;
  }
}
