import {
  Component,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  Input,
  PLATFORM_ID,
  Inject,
} from "@angular/core";
import { isPlatformServer } from "@angular/common";
import anime from "animejs/lib/anime.es.js";
import Swiper from "swiper";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SliderComponent {
  @Input("slides") slides: Array<Object>;
  @Input("smallerHeading") smallerHeading: boolean = false;
  @ViewChild("Slider", { static: false }) Slider: ElementRef;

  public slider;

  public swiperOptions = {
    centeredSlides: true,
    slidesPerView: 1,
    centeredSlidesBounds: true,
    centerInsufficientSlides: true,
    loop: true,
    autoplay: {
      delay: 4000,
    },

    // effect: "fade",
    // fadeEffect: {
    //   crossFade: true,
    // },
    direction: "vertical",
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private translateService: TranslateService
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      // if (!isPlatformServer(this.platformId) && this.isMobile()) {
      if (this.slider) {
        this.slider.destroy();
      }
      this.slider = new Swiper(this.Slider.nativeElement, this.swiperOptions);
    }, 500);
  }

  isRTL() {
    const app = document.querySelector("#app");
    return app.getAttribute("dir") == "rtl" ? true : false;
  }

  // ngOnDestroy() {
  //   if (!isPlatformServer(this.platformId)) {
  //     this.slider.pagination.update();
  //     this.slider.destroy();
  //   }
  // }
}
