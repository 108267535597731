import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LandingPageService {

  constructor(private http: HttpClient) { }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  GetEventsTitles(): Observable<any> {
    return this.http.get<any>(environment.backendUrl + '/event_registration_titles/')
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      )
  }

  GetEventsDetails(): Observable<any> {
    return this.http.get<any[]>(environment.backendUrl + '/eventRegistration_details/')
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      )
  }


  // GET all campaigns titles
  GetCampaignsTitles(): Observable<any> {
    return this.http.get<any>(environment.backendUrl + '/campaign_titles/')
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      )
  }

  // GET all campaigns with details
  GetCampaignsDetails(): Observable<any> {
    return this.http.get<any[]>(environment.backendUrl + '/campaign_details/')
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      )
  }

  // GET all campaigns
  GetCampaigns(): Observable<any> {
    return this.http.get<any[]>(environment.backendUrl + '/campaign_list/')
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      )
  }
  // GET a campaign using its title
  GetCampaignByTitle(title): Observable<any> {
    return this.http.get<any>(environment.backendUrl + '/campaign/' + title + '/')
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      )
  }

  GetEventByTitle(name): Observable<any> {
    return this.http.get<any>(environment.backendUrl + '/event_registration/' + name + '/')
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      )
  }



  // GET all campaigns' templates
  GetTemplates(): Observable<any> {
    return this.http.get<any[]>(environment.backendUrl + '/campaign_template/')
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      )
  }


  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }


}
