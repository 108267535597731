import { AboutUsComponent } from "../pages/about-us/about-us.component";
import { OurRoleRoute } from "./our-role";
import { ExecutiveManagementRoute } from "./executive-management";
// import { BoardMembersRoute } from "./board-members";
import { AwardsAndCertificationsRoute } from "./awards-and-certifications";
import { MetaGuard } from "@ngx-meta/core";

export const AboutUsRoute = {
  path: "about-us",
  canActivateChild: [MetaGuard],
  children: [
    {
      path: "",
      component: AboutUsComponent,
    },
    OurRoleRoute,
    ExecutiveManagementRoute,
    AwardsAndCertificationsRoute,
    // BoardMembersRoute
  ],
  data: {
    meta: {
      title: "About Us",
    },
  },
};
