import { MetaGuard } from '@ngx-meta/core';
import { SitemapComponent } from '../pages/sitemap/sitemap.component';

export const SiteMapRoute = {
  path: 'sitemap',
  canActivateChild: [MetaGuard],
  children: [
    {
      path: '',
      component: SitemapComponent,
    },
  ],
  data: {
    meta: {
      title: 'Site map',
    }
  }
}