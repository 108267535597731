export const MOCK = {
  slides: [
    {
      heading: "about-achievements.header",
      image: '/assets/images/mock/static/achievements.jpg',
    }
  ],
  breadcrumbs: [
    {
      link: "/",
      title: "Home"
    },
    {
      link: "/about-us",
      title: "about.title"
    },
    {
      link: "/achievements",
      title: "about-achievements.header"
    }
  ],
  achievements: {
    title: "about-achievements.title",
    description: "about-achievements.description",
    counter_one_title: "about-achievements.counter_third",
    counter_second_title: "about-achievements.counter_second",
    counter_third_title: "about-achievements.counter_one",
    counter_one_content: "about-achievements.counter_third_content",
    counter_second_content: "about-achievements.counter_second_content",
    counter_third_content: "about-achievements.counter_one_content",
  },
  human_capital: {
    title: "about-achievements.title_human_capital",
    description: "about-achievements.description_human_capital",
    counter_one_title: "about-achievements.counter_one_human_capital",
    counter_second_title: "about-achievements.counter_third_human_capital",
    counter_third_title: "about-achievements.counter_second_human_capital",
    counter_one_content: "about-achievements.counter_one_content_human_capital",
    counter_second_content: "about-achievements.counter_second_content_human_capital",
    counter_third_content: "about-achievements.counter_third_content_human_capital",
  },
}