import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-template2',
  templateUrl: './template2.component.html',
  styleUrls: ['./template2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Template2CampaignComponent {
  @Input('data') data;
  @Input('style') style;

  height = window.screen.height;
  constructor() { }

  ngOnInit() { }
}
