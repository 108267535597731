import { Component, OnInit } from '@angular/core';
import { MOCK } from './mock';
import { PoliciesFormsService } from 'src/app/services/policies-forms.service';
import { DescriptionsService } from 'src/app/services/descriptions.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-policies-and-forms',
  templateUrl: './policies-and-forms.component.html',
  styleUrls: ['./policies-and-forms.component.scss']
})
export class PoliciesFormsComponent implements OnInit {
  lang = 'en';
  mock = MOCK;
  constructor(
    private translate: TranslateService,
    private policiesFormsService: PoliciesFormsService,
    private descriptionsService: DescriptionsService,
  ) { }

  ngOnInit() {

    //description

    this.descriptionsService.GetDescriptionbyName("policies-and-forms").subscribe((page) => {
      this.mock.description = this.translate.getDefaultLang() == 'ar' ? page[0].ar_description : page[0].en_description
      this.lang = this.translate.getDefaultLang() == 'ar' ? 'arr' : 'enn'
    });

    //policies
    this.policiesFormsService.GetPolicies().subscribe((policies) => {
      this.mock.policies = [];
      policies.forEach(policy => {
        this.mock.policies.push({
          title: this.translate.getDefaultLang() == 'ar' ? policy.ar_title : policy.en_title,
          priority: policy.priority,
          tag: policy.tag,
          date: policy.date,
          document: policy.document,
        });
      });
    });

    //forms
    this.policiesFormsService.GetForms().subscribe((forms) => {
      this.mock.forms = [];
      forms.forEach(form => {
        this.mock.forms.push({
          title: this.translate.getDefaultLang() == 'ar' ? form.ar_title : form.en_title,
          priority: form.priority,
          tag: form.tag,
          date: form.date,
          document: form.document,
        });
      });
    });
  }
}




