export const MOCK = {
  slides: [
    {
      heading: "",
      image: "/assets/images/mock/static/executive_page.jpg",
    },
  ],
  breadcrumbs: [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/about-us",
      title: "about.title",
    },
  ],
  columns: [],
  ExecutiveColumns: [],
  BoardColumns: [],
  reverseImage: false,
};
