import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-flexible-columns-with-icon',
  templateUrl: './flexible-columns-with-icon.component.html',
  styleUrls: ['./flexible-columns-with-icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlexibleColumnsWithIconComponent implements OnInit {
  @Input('columns') columns: Array<object>;
  @Input('fullWidth') fullWidth: boolean = false;
  @Input('halfWidth') halfWidth: boolean = false;
  @Input('titleColor') titleColor: string = 'black';
  @Input('background') background: string = '#ffffff';


  constructor() { }

  ngOnInit() {
  }

}
