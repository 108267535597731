import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { MetaModule, MetaLoader } from "@ngx-meta/core";
import { TranslateService } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { HttpClientModule } from "@angular/common/http";
import { MasonryModule, Masonry } from "@thisissoon/angular-masonry";
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from "ngx-scrollbar";
import { InlineSVGModule } from "ng-inline-svg";
import { NgxSpinnerModule } from "ngx-spinner";
import { CookieService } from "ngx-cookie-service";
import { GoogleMapsModule } from "@angular/google-maps";
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";

//Env
import { environment } from "src/environments/environment";

//Routing & Navigation
import { RouterModule } from "@angular/router";
import { routes } from "./routes/routes";
import { I18nModule } from "./i18n/i18n.module";
import { HomeComponent } from "./pages/home/home.component";

//SEO
import { metaFactory } from "./seo/MetaFactory";

//Components
import { AppComponent, getBaseLanguage } from "./app.component";
import { HeaderComponent } from "./layout/header/header.component";
import { NavComponent } from "./layout/header/nav/nav.component";
import { SearchComponent } from "./layout/header/search/search.component";
import { LanguagePickerComponent } from "./layout/header/language-picker/language-picker.component";
import { HomeSliderComponent } from "./components/home/home-slider/home-slider.component";
import { SliderComponent } from "./components/home/slider/slider.component";
import { HomeServicesComponent } from "./components/home/home-services/home-services.component";
import { ParallaxElementComponent } from "./components/parallax-element/parallax-element.component";
import { ListComponent } from "./components/home/home-services/list/list.component";
import { CircleComponent } from "./components/animations/circle-with-icon/circle.component";
import { HomeCertificationsComponent } from "./components/home/home-certifications/home-certifications.component";
import { HomeProductsComponent } from "./components/home/home-products/home-products.component";
import { HomeAchievementsComponent } from "./components/home/home-achievements/home-achievements.component";
import { HomeFeedbackComponent } from "./components/home/home-feedback/home-feedback.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { HomeNewsComponent } from "./components/home/home-news/home-news.component";
import { HomeEstimateComponent } from "./components/home/home-estimate/home-estimate.component";
import { AboutUsComponent } from "./pages/about-us/about-us.component";
import { HeaderSliderComponent } from "./components/header-slider/header-slider.component";
import { BreadcrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";
import { OurRoleComponent } from "./pages/about-us/our-role/our-role.component";
import { AchievementsComponent } from "./pages/about-us/achievements/achievements.component";
import { ExecutiveManagementComponent } from "./pages/about-us/executive-management/executive-management.component";
import { AwardsAndCertificationsComponent } from "./pages/about-us/awards-and-certifications/awards-and-certifications.component";
import { DescriptionComponent } from "./components/flexible/description/description.component";
import { ColumnsWithIconComponent } from "./components/flexible/columns-with-icon/columns-with-icon.component";
import { FlexibleColumnsWithIconComponent } from "./components/flexible/flexible-columns-with-icon/flexible-columns-with-icon.component";
import { CountersComponent } from "./components/flexible/counters/counters.component";
import { CurvedShapeComponent } from "./components/curved-shape/curved-shape.component";
import { BoxesComponent } from "./components/flexible/boxes/boxes.component";
import { LogoSliderComponent } from "./components/flexible/logo-slider/logo-slider.component";
import { ProductsComponent } from "./pages/products/products.component";
import { ProductBoxesComponent } from "./components/flexible/product-boxes/product-boxes.component";
import { ProductComponent } from "./pages/products/product/product.component";
import { NewsComponent } from "./pages/news/news.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { ContactFormComponent } from "./components/flexible/contact-form/contact-form.component";
import { ContactMapComponent } from "./components/flexible/contact-map/contact-map.component";
import { BenefitListComponent } from "./components/flexible/benefit-list/benefit-list.component";
import { FeatureListComponent } from "./components/flexible/feature-list/feature-list.component";
import { NewsListComponent } from "./components/flexible/news-list/news-list.component";
import { AwardsListComponent } from "./pages/about-us/awards-and-certifications/awards-list/awards-list.component";
import { ReportsListComponent } from "./components/flexible/reports-list/reports-list.component";
import { JoinUsComponent } from "./pages/join-us/join-us.component";
import { InstituteComponent } from "./pages/join-us/institute/institute.component";
import { CybersecurityComponent } from "./pages/join-us/institute/cybersecurity/cybersecurity.component";
import { ImageWithContentComponent } from "./components/flexible/image-with-content/image-with-content.component";
import { QuoteComponent } from "./components/flexible/quote/quote.component";
import { ImageBoxListComponent } from "./components/flexible/image-box-list/image-box-list.component";
import { GalleryComponent } from "./components/flexible/gallery/gallery.component";
import { TableWithIconsComponent } from "./components/flexible/table-with-icons/table-with-icons.component";
import { ServicesAndSolutionsComponent } from "./pages/services-and-solutions/services-and-solutions.component";
import { ServicesComponent } from "./components/services-and-solutions/services/services.component";
import { ServicesSliderComponent } from "./components/flexible/services-slider/services-slider.component";
import { ImageFullWidthComponent } from "./components/flexible/image-full-width/image-full-width.component";
import { IconListComponent } from "./components/flexible/icon-list/icon-list.component";
import { ContentTabsComponent } from "./components/flexible/content-tabs/content-tabs.component";
import { ContentComponent } from "./components/flexible/content/content.component";
import { ProgramsComponent } from "./components/flexible/programs/programs.component";
import { PreviousProgramsComponent } from "./components/flexible/previous-programs/previous-programs.component";
import { ErrorNotFoundComponent } from "./pages/error-not-found/error-not-found.component";
import { ArticleComponent } from "./pages/news/article/article.component";
import { ServiceComponent } from "./pages/services-and-solutions/service/service.component";
import { SubserviceComponent } from "./pages/services-and-solutions/subservice/subservice.component";
import { CirclesComponent } from "../app/components/flexible/circles/circles.component";

//STATE
import { ProductService } from "./services/product.service";
import { ArticleService } from "./services/article.service";
import { AwardsAndCertificationsService } from "./services/awards-and-certifications.service";
import { BackendTranslateDirective } from "./directives/backend-translate.directive";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TextTruncateDirective } from "./directives/text-truncate.directive";
import { ConstanceService } from "./services/constance.service";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { PoliciesFormsComponent } from "./pages/policies-and-forms/policies-and-forms.component";
import { SitemapComponent } from "./pages/sitemap/sitemap.component";
import { ScrollTopComponent } from "./components/scroll-top/scroll-top.component";
import { APP_BASE_HREF } from "@angular/common";
import { bulletPointsComponent } from "./components/flexible/bulletPoints/bulletPoints.component";
import { StatisticsCircleComponent } from "./components/flexible/statisticsCircle/statisticsCircle.component";
import { CookiesComponent } from "./pages/cookies/cookies";
import { PickerComponent } from "./layout/header/picker/picker.component";
import { LandingPageComponent } from "./pages/landingPage/LandingPage.component";
import { CampaignHeaderComponent } from "./components/flexible/campaigns/header-campaign/campaign-header.component";
import { contactCampaignT1Component } from "./components/flexible/campaigns/contact-campaignT1/contact.componentT1";
import { FooterCampaignComponent } from "./components/flexible/campaigns/footer-campaign/footer.component";
import { contactCampaignT2Component } from "./components/flexible/campaigns/contact-campaignT2/contact.componentT2";
import { contactEventComponent } from "./components/flexible/campaigns/contact-event/contact.eventComponent";
import { CandidateRegistrationEventComponent } from "./components/flexible/campaigns/contact-candidate-registration/contact.candidateRegistrationEventComponent";
import { Template1CampaignComponent } from "./pages/landingPage/templates/template1/template1.component";
import { Template2CampaignComponent } from "./pages/landingPage/templates/template2/template2.component";
import { CompanyAddressComponent } from "./components/flexible/company-address/company-address.component";
import { EventRegistrationTemplateComponent } from "./pages/landingPage/templates/event_registration/event_registration.component";

const masonryProviders = [
  { provide: Masonry, useFactory: () => window["Masonry"] },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    NavComponent,
    PickerComponent,
    SearchComponent,
    LanguagePickerComponent,
    HomeSliderComponent,
    SliderComponent,
    HomeServicesComponent,
    ParallaxElementComponent,
    ListComponent,
    CircleComponent,
    HomeProductsComponent,
    HomeCertificationsComponent,
    HomeAchievementsComponent,
    HomeFeedbackComponent,
    FooterComponent,
    HomeNewsComponent,
    HomeEstimateComponent,
    AboutUsComponent,
    HeaderSliderComponent,
    BreadcrumbsComponent,
    OurRoleComponent,
    AchievementsComponent,
    ExecutiveManagementComponent,
    AwardsAndCertificationsComponent,
    DescriptionComponent,
    ColumnsWithIconComponent,
    FlexibleColumnsWithIconComponent,
    CountersComponent,
    CurvedShapeComponent,
    BoxesComponent,
    LogoSliderComponent,
    ProductsComponent,
    ProductBoxesComponent,
    ProductComponent,
    NewsComponent,
    ContactComponent,
    ContactFormComponent,
    ContactMapComponent,
    BenefitListComponent,
    FeatureListComponent,
    NewsListComponent,
    ReportsListComponent,
    AwardsListComponent,
    JoinUsComponent,
    InstituteComponent,
    CybersecurityComponent,
    ImageWithContentComponent,
    QuoteComponent,
    ImageBoxListComponent,
    GalleryComponent,
    TableWithIconsComponent,
    ServicesAndSolutionsComponent,
    ServicesComponent,
    ServiceComponent,
    SubserviceComponent,
    ServicesSliderComponent,
    ImageFullWidthComponent,
    IconListComponent,
    ContentTabsComponent,
    ContentComponent,
    bulletPointsComponent,
    ProgramsComponent,
    PreviousProgramsComponent,
    ErrorNotFoundComponent,
    ArticleComponent,
    BackendTranslateDirective,
    TextTruncateDirective,
    PrivacyPolicyComponent,
    PoliciesFormsComponent,
    SitemapComponent,
    ScrollTopComponent,
    CirclesComponent,
    StatisticsCircleComponent,
    CookiesComponent,
    CampaignHeaderComponent,
    FooterCampaignComponent,
    contactCampaignT1Component,
    LandingPageComponent,
    Template1CampaignComponent,
    Template2CampaignComponent,
    contactCampaignT2Component,
    contactEventComponent,
    CandidateRegistrationEventComponent,
    CompanyAddressComponent,
    EventRegistrationTemplateComponent,
  ],
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: environment.enableTracing,
      useHash: false,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
    }),
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: metaFactory,
      deps: [TranslateService],
    }),
    BrowserModule.withServerTransition({ appId: "site-frontend" }),
    GoogleMapsModule,
    AppRoutingModule,
    TransferHttpCacheModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    MasonryModule.forRoot(masonryProviders),
    NgScrollbarModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [
    // {
    //   provide: APP_BASE_HREF,
    //   useFactory: getBaseLanguage
    // },
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        // visibility: 'hover'
      },
    },
    CookieService,
    ProductService,
    ArticleService,
    AwardsAndCertificationsService,
    ConstanceService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
