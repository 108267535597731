import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { MOCK } from "./mock";
import { ConstanceService } from "src/app/services/constance.service";
import { Constance } from "src/app/models/constance";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AboutUsComponent implements OnInit {
  mock = MOCK;

  constructor(private constanceService: ConstanceService) {}

  ngOnInit() {
    // this.constanceService.GetConstances().subscribe((data) => {
    //   this.handleConstances(data);
    // });
  }

  handleConstances(list: Constance) {
    this.mock.expertise.counter_one_content = list.SAUDI_EMPLOYEES;
    this.mock.expertise.counter_second_content = list.EMPLOYEES_SPECIALTIES;
    this.mock.expertise.counter_third_content = list.AVERAGE_EMPLOYEE_AGE;
  }
}
