import { Component, ViewEncapsulation, Input } from '@angular/core';
declare var VanillaTilt;

@Component({
  selector: 'app-flexible-circles',
  templateUrl: './circles.component.html',
  styleUrls: ['./circles.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CirclesComponent {
  @Input('content') content: any;
  @Input('background') background: string = '#FFFFFF';
  @Input('image') image: string = '/assets/images/achievements.svg';
  @Input('index') index: string = '2';

  constructor(
  ) { }

  ngAfterViewInit() {

  }

}
