import { Component, ViewEncapsulation, Input } from '@angular/core';
declare var VanillaTilt;

@Component({
  selector: 'app-home-achievements',
  templateUrl: './home-achievements.component.html',
  styleUrls: ['./home-achievements.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeAchievementsComponent {
  @Input('content') content: any;

  constructor(
  ) { }

  ngAfterViewInit() {

    
  }

}
