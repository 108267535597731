import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ContactService } from 'src/app/services/contact.service';
import { MOCK } from './mock';
import { FormBuilder, FormControl, Validators, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-contact-campaignT2',
  templateUrl: './contact.componentT2.html',
  styleUrls: ['./contact.componentT2.scss'],
  encapsulation: ViewEncapsulation.None
})
export class contactCampaignT2Component implements OnInit {

  @Input('CTA') CTA: any;
  @Input('button_color') button_color: any;
  @Input('opacity') opacity: '0.3';
  @Input('emailSubject') emailSubject: 'SITE Campaign';
  mock = MOCK;
  isRequried = false;
  isSubmitted = false;
  isSpinner = false;
  emailRegex = /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!aol.com)(?!hotmail.co.uk)(?!hotmail.fr)(?!msn.com)(?!yahoo.fr)(?!wanadoo.fr)(?!orange.fr)(?!comcast.net)(?!yahoo.co.uk)(?!yahoo.com.br)(?!yahoo.co.in)(?!live.com)(?!rediffmail.com)(?!free.fr)(?!gmx.de)(?!web.de)(?!yandex.ru)(?!ymail.com)(?!libero.it)(?!outlook.com)(?!uol.com.br)(?!bol.com.br)(?!mail.ru)(?!cox.net)(?!hotmail.it)(?!sbcglobal.net)(?!sfr.fr)(?!live.fr)(?!verizon.net)(?!live.co.uk)(?!googlemail.com)(?!yahoo.es)(?!ig.com.br)(?!live.nl)(?!bigpond.com)(?!terra.com.br)(?!yahoo.it)(?!neuf.fr)(?!yahoo.de)(?!alice.it)(?!rocketmail.com)(?!att.net)(?!laposte.net)(?!facebook.com)(?!bellsouth.net)(?!yahoo.in)(?!hotmail.es)(?!charter.net)(?!yahoo.ca)(?!yahoo.com.au)(?!rambler.ru)(?!hotmail.de)(?!tiscali.it)(?!shaw.ca)(?!yahoo.co.jp)(?!sky.com)(?!earthlink.net)(?!optonline.net)(?!freenet.de)(?!t-online.de)(?!aliceadsl.fr)(?!virgilio.it)(?!home.nl)(?!qq.com)(?!telenet.be)(?!me.com)(?!yahoo.com.ar)(?!tiscali.co.uk)(?!yahoo.com.mx)(?!voila.fr)(?!gmx.net)(?!mail.com)(?!planet.nl)(?!tin.it)(?!live.it)(?!ntlworld.com)(?!arcor.de)(?!yahoo.co.id)(?!frontiernet.net)(?!hetnet.nl)(?!live.com.au)(?!yahoo.com.sg)(?!zonnet.nl)(?!club-internet.fr)(?!juno.com)(?!optusnet.com.au)(?!blueyonder.co.uk)(?!bluewin.ch)(?!skynet.be)(?!sympatico.ca)(?!windstream.net)(?!mac.com)(?!centurytel.net)(?!chello.nl)(?!live.ca)(?!aim.com)(?!bigpond.net.au)([\w-]+\.)+[\w-]{2,4})?$/;
  submitIcon = '/assets/images/mock/campaigns/submit-confirmation.svg';
  spinnerIcon = '/assets/images/Spinner.svg';

  constructor(
    private contactService: ContactService,
    public fb: FormBuilder,
  ) { }

  contactForm = this.fb.group({
    campaign_name: "SITE Campaign",
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    company: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50),
    Validators.pattern(this.emailRegex)]),
    contact_number: new FormControl('', [
      Validators.required, Validators.maxLength(10), Validators.minLength(10),
      Validators.pattern('[0]{1}[0-9]{9}')
    ]),
  })
  ngOnInit() {
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.isSpinner = true;
      this.contactForm.controls.campaign_name.setValue(this.emailSubject);
      this.contactForm.controls.contact_number.setValue('+966' + this.contactForm.value.contact_number.substring(1));
      this.contactService.CampaignContact(this.contactForm.value)
        .subscribe(() => {
          setTimeout(() => {
            this.isSpinner = false;
            this.isSubmitted = true;
          }, 2000);
        })
    } else {
      this.validateAllFormFields(this.contactForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    this.isRequried = true;
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
