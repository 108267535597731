import { JoinUsComponent } from "../pages/join-us/join-us.component";
import { MetaGuard } from '@ngx-meta/core';
import { InstituteRoute } from './institute';

export const JoinUsRoute = {
  path: 'join-us',
  canActivateChild: [MetaGuard],
  children: [
    {
      path: '',
      component: JoinUsComponent,
    },
    InstituteRoute
  ],
  data: {
    meta: {
      title: 'Join Us',
    }
  }
}