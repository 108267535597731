// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: "dev",
  enableTracing: false,
  //backendUrl: 'https://site-backend.test.site.sa', // used backend for testing
  //backendUrl: "https://site-backend.dev.site.sa",
  //prod
  backendUrl: "https://site.sa/api",
  //dev
  // backendUrl: "https://site-stg.site.sa/api",
  // localhost
  // backendUrl: "http://localhost:8000",
  // backendUrl: "https://818.code.cloud.site.sa/api",
  RECAPCHA_KEY: "6LfL6Z8UAAAAAEmMW4pqt0KALOajZ0C79bcT8Eiz",
  googleMapKey: "",
};
