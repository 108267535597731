import { Component, ViewEncapsulation, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Product } from 'src/app/models/product';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from 'src/app/services/product.service';
import { BusinessLineService } from 'src/app/services/business-line.service';
import { BusinessLine } from 'src/app/models/business_line';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';

/**
 * Navigation component
 */
@Component({
  selector: 'app-header-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PickerComponent {
  /**
   * @ignore
   */

  @ViewChild('menu', { static: false }) menu: ElementRef;
  public showSubmenu = true;
  public productList: Array<Product>;
  public productCounter;
  public businessLinesCounter;
  public busineessLines: Array<BusinessLine>;
  public hoverTime = [];
  public disableTime = [];
  public isEnLang: Boolean
  public lang;

  /**
   * Navigation constructor
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private translateService: TranslateService,
    private productService: ProductService,
    private businessLinesService: BusinessLineService,
    private cookieService: CookieService,
    private spinner: NgxSpinnerService
  ) {

    /**
     * GET a list of all products from the backend, and view each 3 products in one column
     */
    this.productService.GetProducts().subscribe((data) => {
      this.productList = data;
      this.productCounter = this.productList.length / 3;
      this.productCounter = Math.ceil(this.productCounter);

      // let arr = [];

      /**
       * Using product id for each product GET its title based on the selected language Ar/En
       */
      // for (let value of this.productList) {
      //   const title = value.en_title.toLowerCase().split(' ').join('_');
      //   arr.push({
      //     title: (this.translate.getDefaultLang() == 'ar') ? value.ar_title : value.en_title,
      //     route: `/products/${title}`
      //   });
      // }

      // this.productList = arr;
    })

    /**
     * GET a list of the provieded services and soultions from the backend, and view each 3 services in one column
     */
    this.businessLinesService.GetBusinessLines().subscribe((data) => {
      this.busineessLines = data;
      this.businessLinesCounter = this.busineessLines.length / 3;
    })
  }
  changeLangText() {
    return (this.translateService.currentLang == 'ar') ? 'English' : 'عربي';
  }

  /**
   * change language
   */
  switchLang() {
    // if (this.translateService.currentLang == 'عربي' && window.location.href.includes('/en')) {
    //   this.langID = true;
    //   this.link = window.location.href.replace('/en', '');
    //   this.langID.HRef = "/";
    // }
    this.spinner.show();
    this.cookieService.set('lang', (this.translateService.currentLang == 'ar') ? 'en' : 'ar', 0, '/', window.location.host.toString().split(":")[0], true);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  ngOnInit() {
    window.location.href.startsWith('/en') ? this.lang = 'en' : this.lang = '';
  }
  /**
   * @ignore
   */
  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId)) {
    }
  }

  /**
   * Show a sub menue when the mouse pointer hover over a tab
   *
   * @param {MouseEvent} ev
   *
   * @param {string} slug
   *
   * @example activateSubmenu(ev, slug)
   */
  activateSubmenu(ev, slug) {
    this.hoverTime[slug] = setTimeout(() => {
      clearTimeout(this.disableTime[slug]);
      ev.target.classList.add('is-active');
    }, 500);
  }

  /**
   * hide the sub menue when the mouse pointer leaves the tab
   *
   * @param {MouseEvent} ev
   *
   * @param {string} slug
   *
   * @example disableSubmenu(MouseEvent, product)
   */
  disableSubmenu(ev, slug) {
    clearTimeout(this.hoverTime[slug]);
    this.disableTime[slug] = setTimeout(() => {
      let hovered = document.querySelectorAll(":hover");
      let check = false;
      let checkifOutside = true;
      hovered.forEach((el) => {
        const target = el as unknown as HTMLElement;

        if (el.classList.contains('header-picker--grab')) {
          checkifOutside = false;
          if (target.dataset.grab != slug) {
            check = true;
          }
        }
      })

      if (checkifOutside) {
        check = true;
      }

      if (check)
        ev.target.classList.remove('is-active');
    }, 500)
  }
  /**
   * @ignore
   */
  // ? why and when it is used?
  openSubmenu(ev) {
    if (window.innerWidth < 1024) {
    const menu = document.querySelector(".header-picker__menu");
      ev.target.nextElementSibling.classList.toggle('header-picker__submenu--opened');
    }
  }

  /**
   * @ignore
   */
  // ? never used
  submenuClick() {
    this.showSubmenu = false;
    setTimeout(() => {
      this.showSubmenu = true;
    })
  }

  /**
   * @ignore
   */
  // ? why and when it is used?
  toggleMobileMenu() {
    const menu = document.querySelector(".header-picker__menu");
    const header = document.querySelector(".header");
    const submenus = document.querySelectorAll(".header-picker--grab");

    submenus.forEach((element) => {
      element.classList.remove('is-active');
    })

    if (menu.classList.contains("header-picker__menu__opened")) {
      this.closeMobileMenu(menu, header)
    } else {
      this.openMobileMenu(menu, header)
    }
  }
  /**
   * @ignore
   */
  closeMobileMenu(menu, header) {
    if (window.innerWidth < 1024) {
      const body = document.querySelector('body');
      menu.classList.remove("header-picker__menu__opened")
      header.classList.remove("header__menuOpened")
      body.classList.remove("menuOpened");
      const mobileMButton = document.querySelector('.header__mobileButton');
      mobileMButton.classList.remove(
        "header__mobileButton--open"
      )
    }
  }
  /**
   * @ignore
   */
  openMobileMenu(menu, header) {
    if (window.innerWidth < 1024) {
      const body = document.querySelector('body');
      menu.classList.add("header-picker__menu__opened")
      header.classList.add("header__menuOpened")
      body.classList.add("menuOpened");
      const mobileMButton = document.querySelector('.header__mobileButton');
      mobileMButton.classList.add(
        "header__mobileButton--open"
      )
    }
  }


}
