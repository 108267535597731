import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServicesComponent implements OnInit {
  @Input('list') list: any;
  ids: Array<String> = [];
  activeIndex;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    if (this.list) {
      for (let service of this.list) {
        if (service.business_line_sections) {
          service.business_line_sections.forEach(element => {
            this.ids.push(element.en_title.toLowerCase().replaceAll(' ', '_'))
            // this.ids.push(element.en_title);
            //              [fragment]="section.en_title?.toLowerCase().replaceAll(' ','_')">{{section.en_title}}></span>
          });
        }
      }
    }
  }

  handleRoute(route, index) {
    if (window.innerWidth > 960) {
      this.router.navigate([route]);
    }

    if (window.innerWidth < 960 && this.activeIndex == index) {
      this.router.navigate([route]);
    }
    this.activeIndex = index;
  }

}
