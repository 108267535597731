import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-image-box-list',
  templateUrl: './image-box-list.component.html',
  styleUrls: ['./image-box-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageBoxListComponent implements OnInit {
  @Input('content') content: any;

  constructor() { }

  ngOnInit() {
  }

}
