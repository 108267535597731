import { InstituteComponent } from "../pages/join-us/institute/institute.component";
import { CybersecurityRoute } from './cybersecurity';
import { MetaGuard } from '@ngx-meta/core';

export const InstituteRoute = {
  path: 'programs',
  canActivateChild: [MetaGuard],
  children: [
    {
      path: '',
      component: InstituteComponent,
    },
    CybersecurityRoute
  ],
  data: {
    meta: {

    }
  }
}