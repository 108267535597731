import { Component, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { CookieService } from "ngx-cookie-service";
import { NgModule } from "@angular/core";
import { APP_BASE_HREF } from "@angular/common";
import { getBaseLocation } from "src/app/functions.util";
import { Router, NavigationEnd } from "@angular/router";

@NgModule({
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseLocation,
    },
  ],
})
/**
 * language picker component
 */
@Component({
  selector: "app-header-language-picker",
  templateUrl: "./language-picker.component.html",
  styleUrls: ["./language-picker.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LanguagePickerComponent {
  langID;
  link;
  public hoverTime = [];
  public disableTime = [];
  submenu = false;

  /**
   * .@ignore
   */

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  /**
   * display current language
   */
  changeLangText() {
    return this.translateService.currentLang == "ar" ? "English" : "عربي";
  }

  /**
   * change language
   */
  switchLang() {
    // if (this.translateService.currentLang == 'عربي' && window.location.href.includes('/en')) {
    //   this.langID = true;
    //   this.link = window.location.href.replace('/en', '');
    //   this.langID.HRef = "/";
    // }
    this.spinner.show();
    this.router
      .navigate(
        [
          "/" +
            `${
              location.pathname.startsWith("/en")
                ? location.pathname.replace("/en", "/ar")
                : location.pathname.replace("/ar", "/en")
            }`,
        ],
        { replaceUrl: true }
      )
      .then(() => {
        window.location.reload();
      });
    // setTimeout(() => {
    //   window.location.reload();
    // }, 500);
  }

  activateSubmenu(ev, slug) {
    this.hoverTime[slug] = setTimeout(() => {
      clearTimeout(this.disableTime[slug]);
      ev.target.classList.add("is-active");
    }, 500);
  }
  disableSubmenu(ev, slug) {
    clearTimeout(this.hoverTime[slug]);
    this.disableTime[slug] = setTimeout(() => {
      let hovered = document.querySelectorAll(":hover");
      let check = false;
      let checkifOutside = true;
      hovered.forEach((el) => {
        const target = el as unknown as HTMLElement;

        if (el.classList.contains("header-language-picker--grab")) {
          checkifOutside = false;
          if (target.dataset.grab != slug) {
            check = true;
          }
        }
      });

      if (checkifOutside) {
        check = true;
      }

      if (check) ev.target.classList.remove("is-active");
    }, 500);
  }

  openSubmenu(ev) {
    // var subMenu = document.getElementById('my-site');
    // if (this.submenu == false) {
    //   if (window.innerWidth < 1024) {
    //     ev.target.nextElementSibling.classList.toggle('header-language-picker__submenu--opened');
    //     subMenu.style.opacity = '1';
    //   }
    //   this.submenu = true;
    // } else {
    //   ev.target.nextElementSibling.classList.toggle('header-language-picker__submenu--opened');

    //   subMenu.style.opacity = '0';
    //   this.submenu = false;
    // }

    if (window.innerWidth < 1024) {
      ev.target.nextElementSibling.classList.toggle(
        "header-language-picker__submenu--opened"
      );
    }
  }
}
