export const MOCK = {
  slides: [
    {
      heading: "our-role.title",
      image: '/assets/images/mock/static/our_role.jpg',
    }
  ],
  breadcrumbs: [
    {
      link: "/",
      title: "Home"
    },
    {
      link: "/about-us",
      title: "about.title"
    },
    {
      link: "/our-role",
      title: "our-role.title"
    }
  ],
  description: 'our-role.paragraph',
  columns: [
    {
      icon: "/assets/images/mock/our-role/icon-1.svg",
      title: "our-role.1-title",
      content: "our-role.1-desc",
    },
    {
      icon: "/assets/images/mock/our-role/icon-2.svg",
      title: "our-role.2-title",
      content: "our-role.2-desc",
    },
    {
      icon: "/assets/images/mock/our-role/icon-3.svg",
      title: "our-role.3-title",
      content: "our-role.3-desc",
    }
  ]
}