import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[textTruncate]'
})
export class TextTruncateDirective {

  el;

  constructor(
    el: ElementRef,
  ) {
   this.el = el;
  }

  @Input('title') title: string;

  ngOnInit() {
    setTimeout(() => {
      if (!this.title) {
        this.title = this.el.nativeElement.innerHTML;
        this.title = this.title.replace(/<[^>]+>[^<]{0,}<\/[^>]+>/ig, '').replace(/<[^>]+>/ig, '');
      }

      this.el.nativeElement.innerHTML = this.title;

      const height = this.el.nativeElement.offsetHeight;
      const width  = this.el.nativeElement.offsetWidth;

      this.el.nativeElement.style.position  = 'absolute';
      this.el.nativeElement.style.width     = `${width}px`;
      this.el.nativeElement.style.height    = 'auto';
      this.el.nativeElement.style.maxHeight = 'inherit';

      let currentHeight   = this.el.nativeElement.offsetHeight;
      const words         = this.title.split(' ');
      const count         = words.length;
      let number          = 0;

      while (currentHeight > height) {

        number++;

        this.title =  words.slice(0, (count - number)).join(' ');
        this.title += '...';
        this.el.nativeElement.innerHTML = this.title;

        currentHeight = this.el.nativeElement.offsetHeight;

      }


      this.el.nativeElement.style.position  = '';
      this.el.nativeElement.style.width     = '';
      this.el.nativeElement.style.height    = '';
      this.el.nativeElement.style.maxHeight = '';
    })
  }


}
