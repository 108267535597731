import { ArticleComponent } from "../pages/news/article/article.component";

export const ArticleRoute = {
  path: ':slug',
  component: ArticleComponent,
  data: {
    meta: {
      
    }
  }
}