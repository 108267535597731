import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-feature-list',
  templateUrl: './feature-list.component.html',
  styleUrls: ['./feature-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeatureListComponent implements OnInit {
  @Input('unordered') unordered: boolean = false;
  @Input('title') title: string = 'product.feature_list';
  @Input('background') background: string = '#FFFFFF';
  @Input('content') content;
  
  constructor() { }

  ngOnInit() {
  }

}
