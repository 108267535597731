export const MOCK = {
  slides: [
    {
      heading: "footer.privacy",
      image: '/assets/images/mock/static/privacy_policy.jpg',
    }
  ],
  breadcrumbs: [
    {
      link: "/",
      title: "Home"
    },
    {
      link: "/privacy-policy",
      title: "privacy-policy.title"
    }
  ],
  description: 'privacy-policy.description',

  loggingPractices_title: 'privacy-policy.logging-practices',
  loggingPractices_content1: 'privacy-policy.logging-practices-description1',
  loggingPractices_content2: 'privacy-policy.logging-practices-description2',
  
  loggingPracticesPoints:[
    {
      title: "",
      content: "privacy-policy.logging-practices-points" 
    }
  ], 

  columns1: [
    {
      title: "privacy-policy.cookies",
      content: "privacy-policy.cookies-description"
    },
    {
      title: "privacy-policy.disclaimer",
      content: "privacy-policy.disclaimer-description"
    },
    {
      title: "privacy-policy.security",
      content: "privacy-policy.security-description"
    }
  ],
  methodology_title : 'privacy-policy.methodology',
  methodology_content : 'privacy-policy.methodology-description',
  methodologyPoints : [
    {
      title: '',
      content: 'privacy-policy.methodology-points'
    }
  ],
    columns2: [
    {
      title: "privacy-policy.share",
      content: "privacy-policy.share-description"
    },
    {
      title: "privacy-policy.liability",
      content: "privacy-policy.liability-description"
    },
    {
      title: "privacy-policy.contact-us",
      content: "privacy-policy.contact-us-description"
    },
  ],
}