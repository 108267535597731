import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-icon-list',
  templateUrl: './icon-list.component.html',
  styleUrls: ['./icon-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IconListComponent implements OnInit {
  @Input('items') items: Array<Object>;
  @Input('paddingBottom') paddingBottom: string;
  @Input('background') background: string;
  lang;

  constructor(
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.translateService.currentLang == 'ar' ? this.lang = 'ar' : this.lang = 'en';
  }

}
