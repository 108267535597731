import {
  Component,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  Inject,
  PLATFORM_ID,
} from "@angular/core";
import { isPlatformServer } from "@angular/common";
import { Product } from "src/app/models/product";
import { TranslateService } from "@ngx-translate/core";
import { ProductService } from "src/app/services/product.service";
import { BusinessLineService } from "src/app/services/business-line.service";
import { BusinessLine } from "src/app/models/business_line";
import { NavigationEnd, Router } from "@angular/router";

/**
 * Navigation component
 */
@Component({
  selector: "app-header-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavComponent {
  /**
   * @ignore
   */

  @ViewChild("menu", { static: false }) menu: ElementRef;
  public showSubmenu = true;
  public productList: Array<Product>;
  public productCounter;
  public businessLinesCounter;
  public busineessLines: Array<BusinessLine>;
  public hoverTime = [];
  public disableTime = [];
  public isEnLang: Boolean;
  public lang;
  public evList = [];
  location: string;

  /**
   * Navigation constructor
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private translate: TranslateService,
    private productService: ProductService,
    private businessLinesService: BusinessLineService,
    private router: Router
  ) {
    /**
     * GET a list of all products from the backend, and view each 3 products in one column
     */
    this.productService.GetProducts().subscribe((data) => {
      this.productList = data;
      this.productCounter = this.productList.length / 3;
      this.productCounter = Math.ceil(this.productCounter);
      this.location = window.location.href;

      // let arr = [];

      /**
       * Using product id for each product GET its title based on the selected language Ar/En
       */
      // for (let value of this.productList) {
      //   const title = value.en_title.toLowerCase().split(' ').join('_');
      //   arr.push({
      //     title: (this.translate.getDefaultLang() == 'ar') ? value.ar_title : value.en_title,
      //     route: `/products/${title}`
      //   });
      // }

      // this.productList = arr;
    });

    /**
     * GET a list of the provieded services and soultions from the backend, and view each 3 services in one column
     */
    this.businessLinesService.GetBusinessLines().subscribe((data) => {
      this.busineessLines = data;
      this.businessLinesCounter = this.busineessLines.length / 3;
    });
  }

  ngOnInit() {
    this.location = window.location.href;
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.location = e.url;
      }
    });

    window.location.href.startsWith("/en")
      ? (this.lang = "en")
      : (this.lang = "ar");
  }
  /**
   * @ignore
   */
  ngAfterViewInit() {
    this.location = window.location.href;
    if (!isPlatformServer(this.platformId)) {
    }
  }

  /**
   * Show a sub menue when the mouse pointer hover over a tab
   *
   * @param {MouseEvent} ev
   *
   * @param {string} slug
   *
   * @example activateSubmenu(ev, slug)
   */
  activateSubmenu(ev, slug) {
    this.hoverTime[slug] = setTimeout(() => {
      clearTimeout(this.disableTime[slug]);
      ev.target.classList.add("is-active");
    }, 500);
  }

  /**
   * hide the sub menue when the mouse pointer leaves the tab
   *
   * @param {MouseEvent} ev
   *
   * @param {string} slug
   *
   * @example disableSubmenu(MouseEvent, product)
   */
  disableSubmenu(ev, slug) {
    clearTimeout(this.hoverTime[slug]);
    this.disableTime[slug] = setTimeout(() => {
      let hovered = document.querySelectorAll(":hover");
      let check = false;
      let checkifOutside = true;
      hovered.forEach((el) => {
        const target = el as unknown as HTMLElement;

        if (el.classList.contains("header-nav--grab")) {
          checkifOutside = false;
          if (target.dataset.grab != slug) {
            check = true;
          }
        }
      });

      if (checkifOutside) {
        check = true;
      }

      if (window.innerWidth > 1024 && check)
        ev.target.classList.remove("is-active");
    }, 500);
  }
  /**
   * @ignore
   */
  // ? why and when it is used?
  openSubmenu(ev, slug?) {
    var isSame = false;
    if (this.evList.length > 0) {
      this.evList.forEach((evElement) => {
        if (evElement.slug == slug) {
          isSame = true;
        }
        if (evElement.slug != slug) {
          evElement.ev.target.nextElementSibling.classList.toggle(
            "header-nav__submenu--opened"
          );
        }
      });
      this.evList = [];
    }

    if (window.innerWidth < 1024) {
      if (isSame == false) {
        this.evList.push({ ev: ev, slug: slug });
      }
      ev.target.nextElementSibling.classList.toggle(
        "header-nav__submenu--opened"
      );
    }
  }

  /**
   * @ignore
   */
  // ? never used
  submenuClick() {
    this.showSubmenu = false;
    setTimeout(() => {
      this.showSubmenu = true;
    });
  }

  /**
   * @ignore
   */
  // ? why and when it is used?
  toggleMobileMenu(item?: any) {
    const menu = document.querySelector(".header-nav__menu");
    const header = document.querySelector(".header");
    const submenus = document.querySelectorAll(".header-nav--grab");

    if (window.innerWidth > 1024) {
      submenus.forEach((element) => {
        element.classList.remove("is-active");
      });
    } else {
      submenus.forEach((element) => {
        if (element.getAttribute("data-grab") != item) {
          element.classList.remove("is-active");
        }
      });
    }

    if (menu.classList.contains("header-nav__menu__opened")) {
      this.closeMobileMenu(menu, header);
    } else {
      this.openMobileMenu(menu, header);
    }
  }
  /**
   * @ignore
   */
  closeMobileMenu(menu, header) {
    if (window.innerWidth < 1024) {
      const body = document.querySelector("body");
      menu.classList.remove("header-nav__menu__opened");
      header.classList.remove("header__menuOpened");
      body.classList.remove("menuOpened");
      const mobileMButton = document.querySelector(".header__mobileButton");
      mobileMButton.classList.remove("header__mobileButton--open");
    }
  }
  /**
   * @ignore
   */
  openMobileMenu(menu, header) {
    if (window.innerWidth < 1024) {
      const body = document.querySelector("body");
      menu.classList.add("header-nav__menu__opened");
      header.classList.add("header__menuOpened");
      body.classList.add("menuOpened");
      const mobileMButton = document.querySelector(".header__mobileButton");
      mobileMButton.classList.add("header__mobileButton--open");
    }
  }
}
