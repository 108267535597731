import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ReportsListComponent implements OnInit {
  @Input('content') content;
  
  isAR = this.translate.getDefaultLang() == 'ar'? true : false;

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    
  }

}
