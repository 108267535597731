import { Component, ViewEncapsulation, ElementRef, ViewChild, Input, PLATFORM_ID, Inject } from '@angular/core';
import {isPlatformServer} from '@angular/common';
import anime from 'animejs/lib/anime.es.js';
import Swiper from 'swiper';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-header-slider',
  templateUrl: './header-slider.component.html',
  styleUrls: ['./header-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderSliderComponent {
  @Input('slides') slides: Array<Object>;
  @Input('smallerHeading') smallerHeading: boolean = false;
  @Input('reverseImage') reverseImage: boolean = false;
  @ViewChild('slider', {static: false}) headerSlider: ElementRef;

  public slider;

  public swiperOptions = {
    autoplay: {
      delay: 6000
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    // direction: 'vertical',
    slidesPerView: 1,
    centeredSlides: true,
    pagination: {
      el: '.header-slider__pagination',
    },
    navigation: {
      nextEl: '.header-slider__next',
      prevEl: '.header-slider__prev',
    }    
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private translateService: TranslateService,
  ) {
  }


  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId)) {

      this.slider = new Swiper(this.headerSlider.nativeElement, this.swiperOptions);

      this.animateCurve();

      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => { 
        this.animateCurve();
      });

      setTimeout(() => {
        this.animateIn();
      },500);

      this.slider.on('slideChange', () => {
        this.animateIn();
        
        if(this.slider.activeIndex== this.slider.slides.length-1)
        {
            setTimeout(() => {
              this.slider.slideTo(0, 600);	
              this.slider.autoplay.start();
            }, 6000)
        }

      });

    }
  }

  animateCurve() {
    setTimeout(() => {
      const curved = document.querySelectorAll('.header-slider__slide--curved');

      anime({
        targets: curved,
        bottom: '-164px',
        rotate: (this.isRTL()) ? 5 : -5,
        duration: 1500,
        easing: 'easeInOutSine'
      }); 
    })
  }

  animateIn() {
    const slide = this.slider.slides[this.slider.realIndex];
    const background = slide.querySelector('.header-slider__slide--background');


    background.style.opacity = 0;
    anime({
      targets: [background],
      opacity: 1,
      easing: 'linear'
    });  

  }

  isRTL() {
    const app = document.querySelector('#app');
    return (app.getAttribute('dir') == 'rtl') ? true : false;
  }

}
