import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-flexible-description",
  templateUrl: "./description.component.html",
  styleUrls: ["./description.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DescriptionComponent implements OnInit {
  @Input("description") description: string;
  @Input("title") title: string;
  @Input("index") index: string = "2";
  @Input("background") background: string = "transparent";
  @Input("paddingBottom") paddingBottom: string = "50px";
  @Input("paddingTop") paddingTop: string = "0px";
  @Input("container") container: string = "964px";
  @Input("style") style: object = { "max-width": this.container };
  @Input("notCentered") notCentered: boolean = false;
  @Input("mobileCentered") mobileCentered: boolean = false;
  @Input("whiteSpaced") whiteSpaced: boolean = false;
  @Input("links") links: Array<any>;

  constructor() {}

  ngOnInit() {}
}
