import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-flexible-product-boxes',
  templateUrl: './product-boxes.component.html',
  styleUrls: ['./product-boxes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductBoxesComponent  {

  activeIndex;

  @Input('title') title: string;
  @Input('list') list: Array<object>;

  constructor(
    private router: Router
  ) { }


  goToProduct(prod, index) {
    if(window.innerWidth > 960) {
      this.router.navigate([prod.route]);
    }

    if(window.innerWidth < 960 && this.activeIndex == index) {
      this.router.navigate([prod.route]);
    }
    
    this.activeIndex = index;
  }
}
