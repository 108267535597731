export const MOCK = {
  slides: [],
  breadcrumbs: [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/news",
      title: "breadcrumbs.news",
    },
  ],
  title: "news.latest",
};
