import { Component, OnInit } from '@angular/core';
import { MOCK } from './mock';
import { ConstanceService } from 'src/app/services/constance.service';
import { Constance } from 'src/app/models/constance';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss']
})
export class AchievementsComponent implements OnInit {

  mock = MOCK;

  constructor(
    private constanceService: ConstanceService
  ) { }

  ngOnInit() {
    this.constanceService.GetConstances().subscribe((data) => {
      this.handleConstances(data);
    })
  }

  handleConstances(list: Constance) {
    this.mock.achievements.counter_one_content = list.SERVICES_AND_PRODUCTS;
    list.CLIENTS != '-1' ? this.mock.achievements.counter_second_content = list.CLIENTS : '';
    // this.mock.achievements.counter_second_content = list.CLIENTS;
    this.mock.achievements.counter_third_content = list.PARTNERS;

    //HCD
    this.mock.human_capital.counter_one_content = list.COURSES;
    list.TRAINEES != '-1' ? this.mock.human_capital.counter_second_content = list.TRAINEES : '';
    // this.mock.human_capital.counter_second_content = list.TRAINEES;
    this.mock.human_capital.counter_third_content = list.TRAINING_HOURS;
  }

}
