export const MOCK = {
  slides: [
    {
      heading: "header-slider.products_subtitle",
      image: '/assets/images/mock/static/products.jpg',
      subtitle: ""
    }
  ],
  breadcrumbs: [
    {
      link: "/",
      title: "Home"
    },
    {
      link: "/products",
      title: "header-slider.products"
    }
  ],
  products_title: "products.title",
  products: [

  ]
}