import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Inject,
  PLATFORM_ID,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { isPlatformServer } from "@angular/common";
import Swiper from "swiper";
import { SubserviceService } from "src/app/services/subservice.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-image-full-width",
  templateUrl: "./image-full-width.component.html",
  styleUrls: ["./image-full-width.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ImageFullWidthComponent implements OnInit {
  @Input("image") image: string;
  @Input("background") background: string = "#FFFFFF";
  @Input("index") index: string = "1";
  @Input("moveTop") moveTop: string;
  @Input("title") title: string;
  @Input("description") description: string;
  @Input("imagePosition") imagePosition: string;
  @Input("items") items: Array<any>;
  @Input("points") points: Array<Object>;
  @Input("benefits") benefits: Array<Object>;

  activeIndex = [];
  itemPicked = [];
  @ViewChild("servicesSlider", { static: false }) servicesSlider: ElementRef;

  public slider;
  public itemsChunks;
  public slug;
  public arrayOfArrays = [];
  public swiperOptions = {
    // autoplay: {
    //   delay: 500,
    // },
    // direction: "vertical",
    slidesPerView: 1,
    // initialSlide: 1,
    allowTouchMove: false,
    touchRatio: 0,
    slidesPerGroup: 1,
    centeredSlides: true,
    centerInsufficientSlides: true,
    centeredSlidesBounds: true,
    // width: "690px",
    // innerWidth: "500px",
    spaceBetween: 20,
    pagination: {
      el: ".image-full-width__slider__pagination",
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + "</span>";
      },
      clickable: true,
    },
    navigation: {
      nextEl: ".image-full-width__slider__next",
      prevEl: ".image-full-width__slider__prev",
    },
    // autoHeight: true,
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private SubserviceService: SubserviceService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    // this.itemPicked = this.items && this.items[0];
    if (this.items) {
      this.route.params.forEach((param) => {
        this.slug = param.slug;
      });
      this.items.forEach(async (service) => {
        let url = "";
        if (service.sub_url !== null) {
          await this.SubserviceService.GetSubservice(service.sub_url).subscribe(
            (u) => {
              url =
                "/services-and-solutions/" +
                this.slug +
                "/" +
                u.en_title.toLowerCase().split(" ").join("_");
              service.sub_url = url;
            }
          );
        }
      });

      this.itemsChunks = new Array(Math.ceil(this.items.length / 3));
      for (var i = 0; i < this.items.length; i += 3) {
        this.arrayOfArrays.push(this.items.slice(i, i + 3));
        this.itemPicked.push(this.items.slice(i, i + 3)[0]);
        this.activeIndex.push(0);
      }
      // console.log("array: ", this.items, this.arrayOfArrays, this.itemsChunks);
      // if (this.slider) {
      //   this.slider.destroy();
      // }
      // this.slider = new Swiper(
      //   this.servicesSlider.nativeElement,
      //   this.swiperOptions
      // );
      // console.log(this.slider);
    }
  }
  ngAfterViewInit() {
    if (this.items && this.items.length > 3) {
      this.slider = new Swiper(
        this.servicesSlider.nativeElement,
        this.swiperOptions
      );
    }
  }
  selectItem(item, index) {
    this.activeIndex = index;
    this.itemPicked = item;
  }
  selectItems(slide, item, index) {
    this.activeIndex[slide] = index;
    this.itemPicked[slide] = item;
  }

  isMobile() {
    if (!isPlatformServer(this.platformId)) {
      return window.innerWidth < 1024 ? true : false;
    } else {
      return false;
    }
  }

  goToProduct(prod, index) {
    this.router.navigate([prod.route]);
  }
}
