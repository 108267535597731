import { Component, ViewEncapsulation, Input } from "@angular/core";
import { MOCK } from "./mock";

@Component({
  selector: "app-home-estimate",
  templateUrl: "./home-estimate.component.html",
  styleUrls: ["./home-estimate.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeEstimateComponent {
  @Input("content") content: any = {
    title: "estimate-home.title",
    description: "estimate-home.desc",
    button: "estimate-home.button",
    link: "/contact-us",
  };
  @Input("background") background: string;
  mock = MOCK;
  constructor() {
    this.mock = this.content;
  }
}
