import { OurRoleComponent } from "../pages/about-us/our-role/our-role.component";

export const OurRoleRoute = {
  path: 'our-role',
  component: OurRoleComponent,
  data: {
    meta: {
      
    }
  }
}