import { Component, OnInit } from "@angular/core";
import { MOCK } from "./mock";

@Component({
  selector: "app-join-us",
  templateUrl: "./join-us.component.html",
  styleUrls: ["./join-us.component.scss"],
})
export class JoinUsComponent implements OnInit {
  mock = MOCK;
  activeIndex = 0;
  itemPicked: any;
  constructor() {}

  ngOnInit() {
    this.itemPicked = this.mock.content && this.mock.content[0];
  }
  selectItem(item, index) {
    this.activeIndex = index;
    this.itemPicked = item;
  }
}
