export const MOCK = {
  heading: "about.title",
  slides: [
    {
      image: "/assets/images/mock/about/Header.jpg",
    },
  ],
  breadcrumbs: [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/about-us",
      title: "about.title",
    },
  ],
  columns: [
    {
      icon: "/assets/images/mock/about/Vision.svg",
      title: "about.vision",
      content: "about.vision-content",
    },
    {
      icon: "/assets/images/mock/about/Mission.svg",
      title: "about.mission",
      content: "about.mission-content",
    },
    // {
    //   icon: "/assets/images/mock/about/Strategic Objectives.svg",
    //   title: "about.objectives",
    //   content: "about.objectives-content"
    // }
  ],
  description: "about.about_desc",
  expertise: {
    title: "about.expertise",
    description: "about.description",
    counter_one_title: "about.counter_third",
    counter_second_title: "about.counter_second",
    counter_third_title: "about.counter_fourth",
    counter_one_content: "about.counter_third_content",
    counter_second_content: "about.counter_second_content",
    counter_third_content: "about.counter_fourth_content",
  },
  values_title: "about.values",
  values: [
    {
      image: "/assets/images/mock/about/Pioneering.svg",
      title: "about.values-2-title",
      desc: "about.values-2-desc",
    },
    {
      image: "/assets/images/mock/about/Agile.svg",
      title: "about.values-1-title",
      desc: "about.values-1-desc",
    },
    {
      image: "/assets/images/mock/about/Persevering.svg",
      title: "about.values-3-title",
      desc: "about.values-3-desc",
    },
    {
      image: "/assets/images/mock/about/Dependable.svg",
      title: "about.values-4-title",
      desc: "about.values-4-desc",
    },
  ],
  logos: [
    {
      logo: "/assets/images/mock/about/METRAS.svg",
    },
    {
      logo: "/assets/images/mock/about/Rakeen.svg",
    },
    {
      logo: "/assets/images/mock/about/Sydr Systems.svg",
    },
  ],
};
// {
//   logo: "/assets/images/mock/about/logo-4.svg",
// },
// {
//   logo: "/assets/images/mock/about/logo-1.svg",
// },
// {
//   logo: "/assets/images/mock/about/logo-2.svg",
// },
// {
//   logo: "/assets/images/mock/about/logo-3.svg",
// },
// {
//   logo: "/assets/images/mock/about/logo-4.svg",
// },
// {
//   logo: "/assets/images/mock/about/logo-1.svg",
// },
// {
//   logo: "/assets/images/mock/about/logo-2.svg",
// },
// {
//   logo: "/assets/images/mock/about/logo-3.svg",
// },
// {
//   logo: "/assets/images/mock/about/logo-4.svg",
// },
