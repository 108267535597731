import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-image-with-content',
  templateUrl: './image-with-content.component.html',
  styleUrls: ['./image-with-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageWithContentComponent implements OnInit {
  @Input('reverse') reverse: boolean = false;
  @Input('image') image: string;
  @Input('title') title: string;
  @Input('description') description: string;
  @Input('background') background: string = '#F8F8F8';
  @Input('index') index: string = '1';
  @Input('moveTop') moveTop: string = '0px';
  @Input('reverseImage') reverseImage: boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
