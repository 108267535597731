import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-header-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent {

  public searchModel;

  constructor() { }

  search() {
    console.log(this.searchModel, 'Searching...');
  }

}
