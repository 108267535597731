import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BusinessLine } from '../models/business_line';


@Injectable({
  providedIn: 'root'
})
export class BusinessLineService {


  constructor(private http: HttpClient) { }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  // GET
  GetBusinessLines(): Observable<BusinessLine[]> {
    return this.http.get<BusinessLine[]>(environment.backendUrl + '/business_line_list/')
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  // GET
  GetBusinessLine(id): Observable<BusinessLine> {
    return this.http.get<BusinessLine>(environment.backendUrl + '/business_line/' + id + '/')
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  // Error handling
  errorHandl(error) {
      let errorMessage = '';
      if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      return throwError(errorMessage);
  }


}
