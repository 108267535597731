import { Component, Input, ViewEncapsulation, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-template1',
  templateUrl: './template1.component.html',
  styleUrls: ['./template1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Template1CampaignComponent {
  @Input('style') style;
  @Input('data') data;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
  ) {  }

  ngOnInit() { }

  isRTL() {
    if (!isPlatformServer(this.platformId)) {
      const app = document.querySelector('#app');
      return (app.getAttribute('dir') == 'rtl') ? true : false;
    }

    return false;
  }
}
