import { Component, ElementRef, ViewChild, PLATFORM_ID, Inject, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { isPlatformServer } from '@angular/common';
import Swiper from 'swiper';

@Component({
  selector: 'app-home-feedback',
  templateUrl: './home-feedback.component.html',
  styleUrls: ['./home-feedback.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeFeedbackComponent {
  @ViewChild('feedbackSlider', {static: false}) feedbackSlider: ElementRef;
  @Input('content') content: any;
  
  public slider;

  public swiperOptions = {
    slidesPerView: 2,
    slidesPerGroup: 2,
    navigation: {
      nextEl: '.home-feedback__next',
      prevEl: '.home-feedback__prev',
    },
    rtl: null
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private translateService: TranslateService,
  ) {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      setTimeout(() => {
        if (!isPlatformServer(this.platformId)) {
          if(this.slider) {
            this.slider.destroy();
          }
          this.swiperOptions.rtl = this.isRTL() ? true : false;
          this.slider = new Swiper(this.feedbackSlider.nativeElement, this.swiperOptions);
        }
      })
    });
  }


  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId)) {
      this.handleResize();

      this.swiperOptions.rtl = this.isRTL() ? true : false;

      if(window.innerWidth < 1024) {
        this.swiperOptions.slidesPerGroup = 1;
        this.swiperOptions.slidesPerView = 1;
      } else {
        this.swiperOptions.slidesPerGroup = 2;
        this.swiperOptions.slidesPerView = 2;
      }
      
      this.slider = new Swiper(this.feedbackSlider.nativeElement, this.swiperOptions);

      this.slider.on('slideChange', () => {
        if(this.slider.activeIndex== this.slider.slides.length-1)
        {
          setTimeout(() => {
            this.slider.slideTo(0, 600);
            this.slider.autoplay.start();
          }, 5000)
        }
      });
    }
  }

  handleResize() {
    window.onresize = (e) => {
      if(this.slider) {
        this.slider.destroy();
      }

      if(window.innerWidth < 1024) {
        this.swiperOptions.slidesPerGroup = 1;
        this.swiperOptions.slidesPerView = 1;
      } else {
        this.swiperOptions.slidesPerGroup = 2;
        this.swiperOptions.slidesPerView = 2;
      }

      this.slider = new Swiper(this.feedbackSlider.nativeElement, this.swiperOptions);
    }
  }

  isRTL() {
    const app = document.querySelector('#app');
    return (app.getAttribute('dir') == 'rtl') ? true : false;
  }
  
}
