import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'app-previous-programs',
  templateUrl: './previous-programs.component.html',
  styleUrls: ['./previous-programs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreviousProgramsComponent implements OnInit {

  @Input('id') id: string;
  @Input('title') title: string;
  @Input('index') index: string;
  @Input('programs') programs: Array<object>;
  @Input('year') year: string;

  activeProgram = 0;

  constructor() { }

  ngOnInit() {
  }

  pickProgram(index, ev) {
    if(this.activeProgram == index) {
      this.activeProgram = -1;
    } else {
      this.activeProgram = index;
    }
    setTimeout(() => {
      const { top } = ev.target.getBoundingClientRect()
      const navHeight = 120
      const scrollCoords = {
        y: window.pageYOffset
      }

      anime({
        targets: scrollCoords,
        y: ((top + window.pageYOffset) - navHeight),
        duration: 500,
        easing: 'easeInOutCubic',
        update: () => window.scroll(0, scrollCoords.y)
      })
    }, 500 )
  }

  objectKeys(items) {
    return Object.keys(items);
  }

  pickYear(year) {
    this.year = year;
  }


}
