import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { MOCK } from "./mock";
import { isPlatformServer } from "@angular/common";

@Component({
  selector: "app-our-role",
  templateUrl: "./our-role.component.html",
  styleUrls: ["./our-role.component.scss"],
})
export class OurRoleComponent implements OnInit {
  mock = MOCK;
  activeIndex = 0;
  itemPicked: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router
  ) {}

  ngOnInit() {
    this.itemPicked = this.mock.columns && this.mock.columns[0];
  }
  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId)) {
      if (window.innerWidth < 768) {
        this.activeIndex = null;
      }
    }
  }
  selectItem(item, index) {
    this.activeIndex = index;
    this.itemPicked = item;
  }
}
