import { MetaGuard } from '@ngx-meta/core';
import { PrivacyPolicyComponent } from '../pages/privacy-policy/privacy-policy.component';

export const PrivacyPolicyRoute = {
  path: 'privacy-policy',
  canActivateChild: [MetaGuard],
  children: [
    {
      path: '',
      component: PrivacyPolicyComponent,
    },
  ],
  data: {
    meta: {
      title: 'Privacy policy',
    }
  }
}