export const MOCK = {
  slides: [],
  breadcrumbs: [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/about-us",
      title: "about.title",
    },
    {
      link: "/about-us/awards-and-certifications",
      title: "breadcrumbs.awards_and_certifications",
    },
  ],
  title: "header.nav.awards_and_certifications",
};
