import { MetaGuard } from '@ngx-meta/core';
import { ContactComponent } from '../pages/contact/contact.component';

export const ContactRoute = {
  path: 'contact-us',
  canActivateChild: [MetaGuard],
  children: [
    {
      path: '',
      component: ContactComponent,
    },
  ],
  data: {
    meta: {
      title: 'Contact Us',
    }
  }
}