import { MetaLoader, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
import { TranslateService } from '@ngx-translate/core';

export function metaFactory(translate: TranslateService): MetaLoader {
  return new MetaStaticLoader({
    callback: (key: string) => translate.get(key),
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' - ',
    applicationName: 'SITE',
    defaults: {
      title: 'Saudi Information Technology Company',
      description: 'Saudi Information Technology Company',
      'og:image': 'https://via.placeholder.com/1200x630',
      'og:type': 'website',
      'og:locale': 'en_US',
      'og:locale:alternate': 'en_US,ar_AR'
    }
  });
}