import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-footer-campaign",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FooterCampaignComponent implements OnInit {
  logo = "assets/images/logo.svg";
  XPlatform = "/assets/icons/XPlatform.svg";

  constructor() {}

  ngOnInit() {}
}
