import { ProductComponent } from "../pages/products/product/product.component";

export const ProductRoute = {
  path: ':slug',
  component: ProductComponent,
  data: {
    meta: {
      
    }
  }
}