import { Component, OnInit } from '@angular/core';
import { MOCK } from './mock';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  mock = MOCK;

  constructor() { }

  ngOnInit() {
  }

}
