import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { Article } from 'src/app/models/article';

@Component({
  selector: 'app-home-news',
  templateUrl: './home-news.component.html',
  styleUrls: ['./home-news.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeNewsComponent implements OnInit{
  public articles: Array<Article>;

  constructor(
    private articleService: ArticleService
  ) { }

  ngOnInit() {
    this.articleService.GetLatestArticles().subscribe((data) => {
      data.forEach(element => {
        element.url= element.en_title.split(' ').join('_').toLowerCase().replace(/[^A-Za-z0-9_]/g, '')
      });
      this.articles = data;
    })
  }


}
