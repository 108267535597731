import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Contact } from "../models/contact";

@Injectable({
  providedIn: "root",
})
export class ContactService {
  constructor(private http: HttpClient) {}

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  // POST
  Contact(data): Observable<Contact> {
    return this.http
      .post<Contact>(
        environment.backendUrl + "/contact_us/",
        JSON.stringify(data),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandel));
  }

  // POST
  CampaignContact(data): Observable<Contact> {
    return this.http
      .post<Contact>(
        environment.backendUrl + "/campaign/",
        JSON.stringify(data),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // POST
  EventContact(data): Observable<Contact> {
    return this.http
      .post<Contact>(
        environment.backendUrl + "/event_registration/",
        JSON.stringify(data),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // POST
  candidateRegistrationContact(data): Observable<Contact> {
    let formData: any = new FormData();
    formData.append("event_name", data.event_name);
    formData.append("candidate_name", data.candidate_name);
    formData.append("major", data.major);
    formData.append("university", data.university);
    formData.append("position", data.position);
    formData.append("experience", data.experience);
    formData.append("email", data.email);
    formData.append("contact_number", data.contact_number);
    formData.append(
      "file_attachment",
      data.file_attachment,
      data.file_attachment.name
    );

    return this.http
      .post<Contact>(
        environment.backendUrl + "/candidate_registration/",
        formData
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  // Error handling
  errorHandl(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
  // Error handling with return
  errorHandel(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(error.error);
  }
}
