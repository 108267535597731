import { Component, OnInit, PLATFORM_ID, Inject, ElementRef, ViewChild, Input } from '@angular/core';
import {isPlatformServer} from '@angular/common';
import Parallax from 'parallax-js';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'app-parallax-element',
  templateUrl: './parallax-element.component.html',
  styleUrls: ['./parallax-element.component.scss']
})
export class ParallaxElementComponent implements OnInit {
  @Input('depthX') depthX: Number;
  @Input('depthY') depthY: Number;
  @Input('width') width: Number;
  @Input('height') height: Number;
  @Input('background') background: String;
  @Input('left') left: String;
  @Input('top') top: String;
  @ViewChild('scene', {static: false}) scene: ElementRef;
  @ViewChild('box', {static: false}) box: ElementRef;

  public parallax;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
  ) { }

  ngOnInit() {
  }


  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId)) {
      this.parallax = new Parallax(this.scene.nativeElement);
      setTimeout(() => {
        anime({
          targets: [this.box.nativeElement],
          opacity: 1,
          easing: 'easeInOutQuad'
        });  
      }, Math.floor(Math.random() * 2500) + 1000  
      )
    }
  }

  isRTL() {
    const app = document.querySelector('#app');
    return (app.getAttribute('dir') == 'rtl') ? true : false;
  }

  parallaxStyle() {
    
    var style = {
      left: 'auto',
      right: 'auto',
      top: this.top
    };
    
    if (!isPlatformServer(this.platformId)) {
      if(this.isRTL()) {
        style.right = this.left.toString();
      } else {
        style.left = this.left.toString();
      }
    }
    
    return style;
  }
}
