import { Component, OnInit } from '@angular/core';
import { MOCK } from './mock';
import { BusinessLineService } from 'src/app/services/business-line.service';

@Component({
  selector: 'app-services-and-solutions',
  templateUrl: './services-and-solutions.component.html',
  styleUrls: ['./services-and-solutions.component.scss']
})
export class ServicesAndSolutionsComponent implements OnInit {

  mock = MOCK;
  services;

  constructor(
    private businessLineService: BusinessLineService
  ) { }

  ngOnInit() {
    this.businessLineService.GetBusinessLines().subscribe((data) => {
      this.services = data;
      this.services.forEach(element => {
        this.businessLineService.GetBusinessLine(element.id).subscribe((service) => {
          element.business_line_sections = service.business_line_sections
        })
      });
    })
  }

}
