export const MOCK = {
  campaignData:
  {
    title: '',
    subtitle: '',
    description: '',
    details_title: '',
    details: '',
    features: '',
    CTA_Button: '',
    emailSubject: '',
  },
  campaignStyle: {
    header_large: '',
    header_laptop: '',
    header_phone: '',
    subtitle_color: '',
    background_color: '',
    description_color: '',
    detailsTitle_color: '',
    logo: '',
    detailsImg: '',
    detailsImgPhone: '',
    detailsImgAr: '',
    detailsImgPhoneAr: '',

    features_background_color: '',
    features_color: '',
  },
  eventData: {
    event_name: null,
    title: null,
    description: null,
    subtitle: null,
    location: null,
    google_maps_url: null,
    time: null,
    date: null,
    url: null,
    sponsors: [],
  },
  eventStyle: {
    title_color: null,
    description_color: null,
    subtitle_color: null,
    location_color: null,
    time_color: null,
    date_color: null,
    background_color: null,
    background_large: null,
    background_laptop: null,
    background_mobile: null,
    logo: null,
  },
  eventContactForm: {
    CTA: null,
    CTA_text_color: null,
    CTA_button_color: null,
    email_Subject: null,
    thx_msg: null,
    thx_msg_color: null,
    more_info: null,
    more_info_color: null,
    more_info_url_title: null,
    more_info_url: null,
    more_info_url_color: null,
    file_attachment: true,
  }
}

