import { Component, ElementRef, ViewChild, Input, PLATFORM_ID, Inject, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformServer } from '@angular/common';
import Swiper from 'swiper';

@Component({
  selector: 'app-flexible-logo-slider',
  templateUrl: './logo-slider.component.html',
  styleUrls: ['./logo-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LogoSliderComponent {

  @Input('slides') slides: Array<Object>;
  @Input('index') index: string = '3';
  @ViewChild('logoSlider', {static: false}) logoSlider: ElementRef;

  public slider;

  public swiperOptions = {
    autoplay: {
      delay: 6000
    },
    // direction: 'vertical',
    slidesPerView: 4,
    slidesPerColumn: 1,
    slidesPerGroup: 4,
    pagination: {
      el: '.flexible-logo-slider__pagination',
    },
    navigation: {
      nextEl: '.flexible-logo-slider__next',
      prevEl: '.flexible-logo-slider__prev',
    },
    autoHeight: true
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private translateService: TranslateService,
  ) {
  }


  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId)) {
      if(window.innerWidth < 1024) {
        this.swiperOptions.slidesPerView = 1;
        this.swiperOptions.slidesPerGroup = 1;
      }
      this.slider = new Swiper(this.logoSlider.nativeElement, this.swiperOptions);
    }
  }

  isRTL() {
    const app = document.querySelector('#app');
    return (app.getAttribute('dir') == 'rtl') ? true : false;
  }

}
