export const MOCK = {
  heading: "",
  subtitle: "",
  image: "",
  slides: [{}],
  breadcrumbs: [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/services-and-solutions",
      title: "breadcrumbs.services",
    },
    {
      link: "/services-and-solutions/enterprise-services-and-solutions",
      title: "SERVICES.ENTERPRISE.title",
    },
  ],
  description: "",
  main_services: [],
  estimate: {
    title: "estimate-home.title",
    description: "estimate-home.desc",
    button: "estimate-home.button",
    link: "/contact-us",
  },
};
