export const MOCK = {
  heading: "services.header",
  slides: [
    {
      image: "/assets/images/mock/services/ServicesSolutions.jpg",
    },
  ],
  breadcrumbs: [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/services-and-solutions",
      title: "services.title",
    },
  ],
  title: "services.title",
  description: "services.paragraph",
};
