import { TranslateService } from '@ngx-translate/core';
translateService: TranslateService;

export function getBaseLocation() {
  let basePath3;
  this.translateService.currentLang ? '' : this.translateService.currentLang = 'ar';
  this.translateService.currentLang == 'ar' ? basePath3 = '/' : basePath3 = '/en';
  let paths: string[] = location.pathname.split('/').splice(1, 1);
  let basePath: string = (paths && paths[0]) || 'my-account'; // Default: my-account
  return basePath3;
}