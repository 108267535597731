import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Article } from '../models/article';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private http: HttpClient) { }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  // GET
  GetArticles(): Observable<Article[]> {
    return this.http.get<Article[]>(environment.backendUrl + '/news_list/')
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }
  GetReports(): Observable<any[]> {
    return this.http.get<any[]>(environment.backendUrl + '/reports_list/')
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }
  // GET
  GetLatestArticles(): Observable<Article[]> {
    return this.http.get<Article[]>(environment.backendUrl + '/news_latest/')
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  // GET
  GetArticle(id): Observable<Article> {
    return this.http.get<Article>(environment.backendUrl + '/news_details/' + id + '/')
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  // Error handling
  errorHandl(error) {
      let errorMessage = '';
      if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      return throwError(errorMessage);
  }


}
