import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-bulletPoints',
  templateUrl: './bulletPoints.component.html',
  styleUrls: ['./bulletPoints.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class bulletPointsComponent implements OnInit {

  @Input('id') id: string;
  @Input('title') title: string;
  @Input('descriptions') descriptions: Array<object>;
  @Input('paddingBottom') paddingBottom: string;

  
  constructor() { }

  ngOnInit() {
  }

}
