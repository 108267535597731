import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-statisticsCircle',
  templateUrl: './statisticsCircle.component.html',
  styleUrls: ['./statisticsCircle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatisticsCircleComponent implements OnInit {

  @Input('index') index: string = '3';
  @Input('background') background: string = '#ffffff';  
  @Input('programs') data: Array<any>;
  content;

  constructor() { }

  ngOnInit() {
    this.content = this.data;
  }


}
