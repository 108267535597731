import { CybersecurityComponent } from '../pages/join-us/institute/cybersecurity/cybersecurity.component';

export const CybersecurityRoute = {
  component: CybersecurityComponent,
  path: 'cybersecurity-champions-program',
  children: [
    {
      path: '',
      component: CybersecurityComponent,
    },
  ],
  data: {
    meta: {
      
    }
  }
}