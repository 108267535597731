export const MOCK = {
  slides: [
    {
      heading: "policies-and-forms.title",
      image: '/assets/images/mock/static/polices_forms.jpg',
    }
  ],
  breadcrumbs: [
    {
      link: "/",
      title: "Home"
    },
    {
      link: "/policies-and-forms",
      title: "policies-and-forms.title"
    }
  ],
  description: '',
  policesTitle: 'policies-and-forms.policies',
  policies: [
    {
    }
  ],
  FormsTitle: 'policies-and-forms.forms',
  forms:[

  ],
}